import React, { useState }  from 'react';
import {Button} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faTimes} from '@fortawesome/free-solid-svg-icons'
import {
    BrowserView,
    MobileView,
  } from "react-device-detect";
//   search bar component which is imported all over the app ?
const SearchBar =()=>{
    const [keyword,setKeyword]=useState('')
    const history = useHistory();
    const [show,setShow]=useState(false)
    const handleSearch =(e)=>{
        e.preventDefault();
        // if keyword is here and not space go 
        if(keyword&&keyword.trim()!=''){history.push("/searchresult/"+keyword)}
    }
    const handleShowSearch=()=>{
        setShow(!show)
    }
    return(
        <>
        {/* change search layout depend on device type */}
        <BrowserView>
            <Button variant="primary" className='search-btn'>
                <FontAwesomeIcon className='search-icon' icon={faSearch}/>
            </Button>
            <form className='search-form' onSubmit={handleSearch}>
                <input required className='search-input' type='text' onChange={(e)=>setKeyword(e.target.value)} placeholder='إكتب كلمة البحث'/>
                <button className='search-icon-btn'  type='submit'>
                <FontAwesomeIcon className='search-icon' icon={faSearch}/>
                </button>
            </form>
        </BrowserView>
        <MobileView>
            <div className='search-cont mobile-search'>

            <Button variant="primary" onClick={handleShowSearch} className='search-btn'>
                {show?(<FontAwesomeIcon className='search-icon shake' icon={faTimes}/>):(<FontAwesomeIcon className='search-icon' icon={faSearch}/>)}
            </Button>
            {show?(<form className='search-form' onSubmit={handleSearch}>
                <input className='search-input' type='text' onChange={(e)=>setKeyword(e.target.value)} placeholder='إكتب كلمة البحث'/>
                <button class='search-icon-btn'onClick={handleSearch} >
                <FontAwesomeIcon className='search-icon' icon={faSearch}/>
                </button>
            </form>):(null)}
            
            </div>
        </MobileView>
            
        </>
    )
}
       

export default SearchBar
