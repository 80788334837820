import React, { Component } from 'react';
import { Button , Form , Alert } from 'react-bootstrap';

class DepartmentForm extends Component{
    state={
        department:this.props.department?this.props.department:'' //store department in case of
    }
    handleChange=(e)=>{
        this.setState({department:e.target.value})
    }
    handleSubmit=(e)=>{
      e.preventDefault();
      const department = this.state.department;
      this.props.ctype=='add'? //check type of modal to determine which function will be fired       
      this.props.createDepartment(department)
      :this.props.editDepartment(department,this.props.id)
    }
    
    render(){
        return(
            <Form className='text-right' onSubmit={this.handleSubmit}>
                <Form.Group controlId="addDepartment">
                <label htmlFor="addDepartment">اسم القسم</label>
                    <Form.Control pattern="[أ-يa-zA-Z\s]+" required value={this.state.department} type="text" onChange={this.handleChange} placeholder="اسم القسم" />
                </Form.Group>
                <Form.Group className='text-center'>
                     <Button  variant="primary" type="submit">
                      حفظ
                    </Button>
                </Form.Group>
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null} 
            </Form>
        )
    }
}

  export default DepartmentForm
