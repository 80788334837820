import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import FormData from 'form-data'
class ItemForm extends Component{
    constructor(props){
        super(props);
        this.state={
            name:this.props.item.name?this.props.item.name:'',
            credit_per_spoon:this.props.item.credit_per_spoon?this.props.item.credit_per_spoon:'',
            alert_level:this.props.item.alert_level?this.props.item.alert_level:'',
            convert_ratio:this.props.item.convert_ratio?this.props.item.convert_ratio:'',
            image:this.props.item.image?this.props.item.image:'',
            quantity:this.props.item.quantity?this.props.item.remain_quantity:'',
            // ratio:this.props.item.ratio?this.props.item.ratio:'',
            label:this.props.item.label?this.props.item.label:'',
        }
    }
    
    handleChange =(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangeImg =(e)=>{
        this.setState({[e.target.name]:e.target.files[0].name})
    }
    
    handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(document.querySelector('#userform'));
        this.props.type =='add'? this.props.createItem(data):this.props.editItem(data,this.props.item.id)

    }
    

    render(){
       
        return(
            <Form  id="userform" className='text-right' onSubmit={this.handleSubmit}>
                <Form.Group controlId="name">
                    <label htmlFor="name">إسم المنتج</label>
                    <Form.Control  required name='name' type="text" value={this.state.name} onChange={this.handleChange} placeholder="اسم المنتج" />
                </Form.Group>
                <Form.Group controlId="credit_per_spoon">
                <label htmlFor="credit_per_spoon">نقاط/وحدة</label>
                    <Form.Control  required name='credit_per_spoon' value={this.state.credit_per_spoon} min="0"  type="number" onChange={this.handleChange} placeholder="نقاط/وحدة" />
                </Form.Group>
                <Form.Group controlId="alert_level">
                <label htmlFor="alert_level">الحد الادني (نسبة مئوية من الوحدات)</label>
                    <Form.Control  required name='alert_level' value={this.state.alert_level} min="0" step='.25' type="number" onChange={this.handleChange} placeholder="الحد الادني" />
                </Form.Group>
                <Form.Group controlId="convert_ratio">
                <label htmlFor="con-convert_ratio"> معامل التحويل (عدد الوحدات للكمية المدخلة)</label>
                {this.props.type =='add'?(
                    <Form.Control  required name='convert_ratio' value={this.state.convert_ratio} min="1"   type="number" onChange={this.handleChange} placeholder="معامل التحويل" />
                ):(
                    <Form.Control   name='convert_ratio' type="number" onChange={this.handleChange} placeholder="معامل التحويل" />
                )}
                </Form.Group>
                <Form.Group controlId="quantity">
                <label htmlFor="quantity">الكمية</label>
                    <Form.Control  required name='quantity' value={this.state.quantity} min="1"  type="number" onChange={this.handleChange} placeholder="الكمية" />
                </Form.Group>
                {/* <Form.Group controlId="ratio">
                <label htmlFor="ratio">نسبة التعامل</label>
                    <Form.Control  required name='ratio' value={this.state.ratio} min="0"  type="number" onChange={this.handleChange} placeholder="نسبة التعامل" />
                </Form.Group> */}
                <Form.Group controlId="label">
                <label htmlFor="label">الوحدة</label>
                    <Form.Control  required name='label' value={this.state.label} type="text" onChange={this.handleChange} placeholder="الوحدة" />
                </Form.Group>
                
                <Form.Group className='relative'>
                    <input id="img" className='form-control ' type="text" disabled value='صورة المنتج'/>
                    <div className="file btn btn-lg btn-primary col-auto">
                        تحميل
                        <input required={this.props.type=='add' ? 'required' : null} type="file"  name='image' onChange={this.handleChangeImg}/>
                    </div>                

                </Form.Group>
                <Form.Group className='text-center'>
                     <Button  variant="primary" type="submit">
                      حفظ
                    </Button>
                </Form.Group>
               
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default ItemForm