import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import FormData from 'form-data'
class CategoryForm extends Component{
    constructor(props){
        super(props);
        this.state={
            name:this.props.category.name?this.props.category.name:'',
            image:this.props.category.image?this.props.category.image:'',
        }
    }
    //storing input values into state
    handleChange =(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    //storing image value into state
    handleChangeImg =(e)=>{
        this.setState({[e.target.name]:e.target.files[0].name})
    }
    // submittinig form and check is it add or edit form
    handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(document.querySelector('#userform'));
        this.props.type =='add'? this.props.createCategory(data):this.props.editCategory(data,this.props.category.id)

    }
    

    render(){
       
        return(
            <Form  id="userform" className='text-center' onSubmit={this.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Control required name='name' type="text" value={this.state.name} onChange={this.handleChange} placeholder="اسم التصنيف" />
                </Form.Group>
                <Form.Group className='relative'>
                    <input className='form-control ' type="text" disabled value='صورة التصنيف'/>
                    <div className="file btn btn-lg btn-primary col-auto">
                        تحميل
                        <input required={this.props.type=='add' ? 'required' : false} files={this.state.image?this.state.image :''} type="file"   name='image' onChange={this.handleChangeImg}/>
                    </div>                

                </Form.Group>

                <Button  variant="primary" type="submit">
                    حفظ
                </Button>
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default CategoryForm