import React ,{Component} from 'react';
import {Button,Dropdown} from 'react-bootstrap';
import { Link ,withRouter } from 'react-router-dom'

import SweetAlert from 'react-bootstrap-sweetalert';
import {connect} from 'react-redux'
import defaultImage from '../../assets/img/default.png'
import notificationIcon from '../../assets/img/notification.svg'
import logoIcon from '../../assets/img/logo_icon.svg'
import cartIcon from '../../assets/img/cart.svg'
import SideBar from './SideBar'
import Notifications from './Notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faPowerOff,faTrash} from '@fortawesome/free-solid-svg-icons';
import { NavLink} from 'react-router-dom'
import serverPath from '../config'
import axios from 'axios'

class TopBar extends Component {
    constructor(props){
        super(props);
        this.state={
            errmsg:'',
            cerrmsg:'',
            show:'',
            alertDelete:'',
            alertSuccess:'',
            loading:true,
            cartItems:this.props.cartItems?this.props.cartItems:[],
            menu:'',
            profilePic:'',
            openCart:false,
            notificationsList:[]
        }
    }

    async componentDidMount(){
       
      await this.fillData()
      await this.realTimeNotification()
      await this.realTimeDeleteNotification()

    }
    fillData =()=>{
        Promise.all([
            // compine requests to get one response as an array of responses     
            axios.get(`${serverPath}/api/get_cart/${this.props.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
            axios.get(`${serverPath}/api/User/${this.props.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
            axios.get(`${serverPath}/api/unreaded_notifications`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
          
        ])
        .then(([responseOne,responseTwo,responseThree])=>{
            const cartAllItems= responseOne.data.cart;
            const cartItems=[]
            cartAllItems.map(item=>{
                cartItems.push(item)
            })
            const profilePic =responseTwo.data.data.profile_pic;
            const points =responseTwo.data.data.points;
            const credit =responseTwo.data.data.credit;
            const notificationsList =responseThree.data.data;

            // saving cartitems
            this.props.CartItems(cartItems)
            // change profile pic and save it into store
            this.props.ChangeImg(profilePic)
            // change points and save it into store
            this.props.points(points)
            // change credit and save it into store
            this.props.credit(credit)
            this.setState({profilePic,notificationsList})
        })
        // catch errors of all reequests
        .catch(errors => {
            this.setState({errmsg:errors.message})
            if(errors.response.status==403){
                this.setState({nopermission:true})
            }
        })
    }
    realTimeNotification=()=>{
    //     window.Echo.channel('notification-channel').listen(".notification-event", notifi=> {
            
    //     if(!notifi.notification_id){
    //         if(notifi.user_id==this.props.id){
    //         const notificationsList=this.state.notificationsList
    //         notificationsList.unshift(notifi)
    //         this.setState({notificationsList})
    //     }
    //       }
    //       else{
    //         const notificationsList=this.state.notificationsList.filter(item=>{
    //             return item.id!=notifi.notification_id

    //         })

    //         this.setState({notificationsList})
    //       }
    //   });
      

    }
    realTimeDeleteNotification=()=>{    
        // window.Echo.channel('delete-notification-channel').listen(".delete-notification-event", notifi=> {
        //     if(notifi.notification_id=='all'){
              
        //       this.setState({notificationsList:[]})
        //   }
        //   else{

        //       const notificationsList=this.state.notificationsList.filter(item=>{
        //           return item.id!=notifi.notification_id
  
        //       })

        //       this.setState({notificationsList})
        //   } 
        // });
    }
    onCancel= ()=> {
        this.setState({alert:'',alertDelete:''})
    }
     toggleMenu = ()=>{
        this.setState({menu:!this.state.menu})
    }


     showalert = ()=>{
        return(
            this.setState({alert:
                <SweetAlert
                warning
                showCancel
                confirmBtnText="نعم , سجل خروج"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="primary"
                cancelBtnText='لا اود'
                title="هل انت متأكد ؟"
                onConfirm={this.props.logout}
                onCancel={this.onCancel}
              >
              هل تود تسجيل الخروج من بوفيه ديموفنف 
              </SweetAlert>
            })
            )
    }
    showAlertSuccess=(msg)=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title={msg}
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    checkOut=()=>{
    
         axios.post(`${serverPath}/api/make_order`,{user_id:`${this.props.id}`}, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            this.props.CartItems('')
            this.showAlertSuccess(response.data.message)
        },(error)=>{
            if(error.response){
                this.setState({cerrmsg : error.response.data.message,loading:false,show:true
                })  
            }
            else{
              this.setState({cerrmsg : null})
            }
          })
    }
    clearCart=()=>{
    
         axios.get(`${serverPath}/api/empty_cart`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            this.props.ClearCart('')
            this.setState({cartItems:[]})
            this.showAlertSuccess(response.data.message)
        },(error)=>{
            if(error.response){
                this.setState({cerrmsg : error.response.data.message,loading:false,show:true
                })  
            }
            else{
              this.setState({cerrmsg : null})
            }
          })
    }
    openCart=(path)=>{
        this.props.history.push(path);
        // this.toggleCart()
    }
     handleDelete=(id)=>{
        const headers=   {
            'Authorization':'Bearer '+this.props.token
        }
        const cartItems = this.state.cartItems.filter(item=>{
            return item.id !== id
        })
        axios.get(`${serverPath}/api/delete_cart/${id}`, {headers: headers}).then((response)=>{
            this.showAlertSuccess(response.data.message)
            this.setState({cartItems:response.data.cart})
            this.props.CartItems(response.data.cart)
    
        }, (error) => {
            if(error.response){
                this.setState({errmsg : error.response.data.message})
                this.setState({show : true})   
            }
            else{
              this.setState({errmsg : null})
            }
          })
        this.setState({alertDelete:''})
    } 
     showAlertDelete=(id)=>{
        this.setState({
            alertDelete:<SweetAlert
            danger
            showCancel
            confirmBtnText="حذف"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            cancelBtnText='رجوع'
            title="هل انت متأكد من مسح المنتج من السلة ؟"
            onConfirm={()=>this.handleDelete(id)}
            onCancel={this.onCancel}
          >
        </SweetAlert>
        })
    } 
     toggleCart=()=>{
         const openCart=this.state.openCart
         this.setState({openCart:!openCart})
     }
render(){
 
    const carts = this.props.cartItems.length>0?(
        this.props.cartItems.map(item=>{
            return(
                <div className='cart-row' key={item.id}>
                    <span className='item-name'>
                        {item.preset.name}
                    </span>
                    <div className='item-controls'>
                        <span className='item-price'>
                            النقاط : {item.points} نقاط
                        </span>
                        <span className='cart-btns'>
                            <Button onClick={()=>this.showAlertDelete(item.id)} className='cart-btn' variant="danger" >
                                <FontAwesomeIcon  icon={faTrash}/>
                            </Button>
                        </span>
                    </div>
                </div>
            )
            
        })
    ):(<div className='cart-row noitems'>
    لا توجد طلبات جديدة في السلة ليتم تنفيذها.
</div>)
    return(
        <>
            {this.state.alertDelete}
            {this.state.alertSuccess}
            {this.realTimeNotification}
            {this.realTimeDeleteNotification}
            <div className='topbar cv'>
                {this.state.alert}
                <Dropdown  className='cart-popup' >
                    <span className='cart-counter'>
                        {this.props.cartItems.length}
                    </span>
                    <Dropdown.Toggle  variant="topbar" id="dropdown-basic">
                            <span>
                                السلة
                            </span>
                    </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {carts}
                        <div className='cart-popup-btns'>
                            <Button onClick={()=>this.openCart('/cart')} variant='primary'>أكمل الطلب</Button>
                            <Button onClick={this.clearCart} variant='danger'>تفريغ السلة</Button>
                        </div>
                    </Dropdown.Menu>
                    
                    
                </Dropdown>
                
               
                <Dropdown className='notification-popup'>
                    <span className='notification-counter'>
                        {this.state.notificationsList.length}
                    </span>
                    <Dropdown.Toggle variant="icon" id="dropdown-basic1">
                        <img src={notificationIcon} alt='Dimofinf Buffet' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='notifications-dropdown'>
                        <Notifications notificationsList={this.state.notificationsList}/>
                        <NavLink activeClassName='notifi' to='/notifications' className='btn btn-primary'>جميع الاشعارات</NavLink>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='user-menu'>
                    <Dropdown.Toggle variant="usermenu" id="dropdown-basic">
                        <img src={this.props.profilePic ? this.props.profilePic:defaultImage} alt='Dimofinf Buffet' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <span className='arrowUp'></span>
                        <Dropdown.Item onClick={this.showalert}><FontAwesomeIcon icon={faPowerOff}/>
                                <span>تسجيل خروج </span></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            
                
            </div>
            <div className='topbar mv'>
                
                <div className='right'>
                    <Button variant="menu" onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon={faBars}/>
                    </Button>
                    <NavLink to='/' className='d-flex'>
                    <img src={logoIcon} alt='dimofinf buffet'/>
                    </NavLink>
                </div>
                
                {this.alert}
                <div className='left'>
                    <Link  to="/cart" className="btn btn-icon relative">
                    <span className='cart-counter'>
                        {this.props.cartItems.length}
                    </span>
                        <img src={cartIcon} alt='DImofinf Buffet'/>
                    </Link>
                    <Link  to="/notifications" className="btn btn-icon relative">
                    <span className='notification-counter'>
                        {this.state.notificationsList.length}
                    </span>
                        <img src={notificationIcon} alt='Dimofinf Buffet' />
                    </Link>

                </div>
                

            </div>
            <div className='mv'>
            <SideBar showalert={this.showalert} defaultImage={defaultImage} show={this.state.menu} toggleMenu={this.toggleMenu}/>
            {this.state.alert}
            </div>
        </>
    )
}
       
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        id:state.user.id,
        profilePic:state.user.profilePic,
        cartItems:state.user.cartItems,
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      ChangeImg: (profilePic)=>dispatch({type:'CHANGEIMG', profilePic:profilePic}),
      points: (points)=>dispatch({type:'POINTS', points:points}),
      CartItems: (cartItems)=>dispatch({type:'CARTITEMS', cartItems:cartItems}),
      credit: (credit)=>dispatch({type:'CREDIT', credit:credit}),
      ClearCart:(cartItems)=>dispatch({type:'CLEARCART',cartItems:cartItems})
    }
  }

export default connect(mapStateToProps,mapDispatchToProps) (withRouter (TopBar))
