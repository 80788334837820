import React, { Component } from 'react';
import { connect } from 'react-redux'
import addOrderImg from '../../assets/img/add-order-btn.svg'
import axios from 'axios'
import serverPath from '../config'
import './Dashboard.scss'
import { Planets } from 'react-preloaders';
import EmployeeConsumedPoints from './EmployeeConsumedPoints'
import ConsumedItems from './ConsumedItems'
import EmployeeRating from './EmployeeRating'
import ConsumedPresets from './ConsumedPresets'
import { Line } from 'react-chartjs-2'

class Dashboard extends Component {
    state = {
        statistics: '',
        loading: true
    }
    componentDidMount() {
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        wrapper.style.height = 'calc(100vh - ' + offset + 'px - 40px)'
        let cerrmsg=this.state.cerrmsg
        axios.get(`${serverPath}/api/statistics`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then((response) => {
                this.setState({ statistics: response.data.data, loading: false })
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (cerrmsg += err + " . "))
                    this.setState({show: true,cerrmsg, loading: false})
        
                }
                
                else {
                    this.setState({ cerrmsg: null })
                }
            })
    }
    
    render() {
        
        const statistics = this.state.statistics;
        const permonth = statistics.Employees_Total_Orders_Per_Months ? statistics.Employees_Total_Orders_Per_Months : (null)
        let chartLabels = [];
        let chartValues = [];

        let result = permonth ? Object.keys(permonth).map(function (key) {
            chartLabels.push(key)
            chartValues.push(permonth[key])
        }) : null
      let data ={
          labels:chartLabels,
          
      
          datasets:[
              {
                label: 'عدد الطلبات',
                data:chartValues,
                borderColor: 'rgba(255,255,255,1)',
                backgroundColor: 'rgba(75,192,192,0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(255,255,255,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(255,255,255,1)',
                pointHoverBorderColor: 'rgba(255,255,255,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                spanGaps:true
              }      
          ]
      }
        
        return (
            <div className="dashboard">
                <div className="container-fluid">
                        <div className='buffet-wrapper'>
                        <Planets customLoading={this.state.loading} />
                        <div className='dashboard-welcome'>
                            <h2>مرحبا بعودتك</h2>
                            <h1>{this.props.username}</h1>
                            <p>يمكنك من خلال هذه اللوحة متابعة احصائيات البوفيه</p>
                            <img src={addOrderImg} alt="dim buffet" />
                        </div>
                        <div className='statistics'>
                            <div className="statistics-box full flex-row h-scroll">
                                <div className="box">
                                    <h4>عدد الطلبات الملغاه</h4>
                                    <h3>
                                    {statistics.Number_Of_Canceled_Orders > 0 ? statistics.Number_Of_Canceled_Orders : 0}
                                    </h3>
                                    <h4>طلب</h4>
                                </div>
                                <div className="box blue">
                                    <h4>إجمالي عدد الطلبات</h4>
                                    <h3>
                                        {statistics.Employees_Total_Orders > 0 ? statistics.Employees_Total_Orders : 0}
                                    </h3>
                                    <h4>طلب</h4>
                                </div>
                                <div className="box dark">
                                    <h4>معدل وقت تجهيز الطلب</h4>
                                    <h3>
                                        {statistics.Average_Order_Preparing_Duration > 0 ? Number(statistics.Average_Order_Preparing_Duration.toString().slice(0,5)) : 0}
                                    </h3>                              
                                    <h4>دقيقة</h4>
                                </div>
                                <div className="box ">
                                    <h4>عدد النقاط المستهلكة</h4>
                                    <h3>
                                        {statistics.Total_Credits_Spent > 0 ? statistics.Total_Credits_Spent : 0}
                                    </h3>                              
                                    <h4>نقطة</h4>
                                </div>
                            </div>
                            {/* stats boxes each component get 1 box  */}
                            <div className="statistics-box full flow-auto">
                                {/* orders chart */}
                                <Line  data={data} responsive={true} options={{
                                    legend: {
                                        labels: {
                                            fontColor: "white",
                                            fontSize: 18
                                        }
                                    },
                                        scales: {
                                    xAxes: [{ 
                                        gridLines: {
                                            display:false,

                                            zeroLineColor: '#fff'
                                        },
                                        ticks: {
                                            fontColor: "#fff", // this here
                                        },
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            zeroLineColor: '#fff'
                                        },
                                        ticks: {
                                            fontColor: "#fff", // this here
                                        },
                                    }],
                                    
                                }
                                }} />
                            </div>
                            <div className="statistics-box">
                                {/* overall buffet employee rating based on orders reviews */}
                                <EmployeeRating data={this.state.statistics.Employee_Reviews}/>
                            </div>
                            <div className="statistics-box">
                                {/* top 5 and lowest 5 comsumed items */}
                                <ConsumedItems top={this.state.statistics.Top_5_Consumed_Items} low={this.state.statistics.Lowest_5_Consumed_Items}/>
                            </div>
                            <div className="statistics-box">
                                {/* highest employees consumed */}
                                <EmployeeConsumedPoints data={this.state.statistics.Highest_Credit_Spent_By_Employee}/>
                            </div>
                            <div className="statistics-box">
                                {/* highest consumed presets */}
                                <ConsumedPresets data={this.state.statistics.Top_5_Consumed_Presets}/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )


    }
}

const mapStateToProps=(state)=>{
    return{
        permissions:state.user.permissions,
        username:state.user.username,
        token:state.auth.token,
        usertype:state.user.usertype
    }
}

export default connect(mapStateToProps) (Dashboard)