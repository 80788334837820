import React, { Component } from 'react'
import { Button , Alert,Modal , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import itemImg from '../../assets/img/default.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoryForm from './CategoryForm'
import { faTrash ,faPen} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import SearchBar from '../layout/SearchBar'

class ItemsList extends Component{
    constructor(props){
        super(props);
        this.state={
            categories:[],
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            errmsg:'',
            cerrmsg:'',
            show:'',
            alertDelete:'',
            alertSuccess:'',
            loading:true
        }
    }
   async componentDidMount(){
       let cerrmsg=this.state.cerrmsg
       // getting category data once component loaded 
    await axios.get(`${serverPath}/api/Category`, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const categories= response.data.data;
       this.setState({categories,loading:false})
    },(error)=>{
        if(error.response.status === 403){
            this.props.history.push('/nopermission')
         }
         if (error.response) {
            error.response.data.errors.map(err => (cerrmsg += err + " . "))
            this.setState({show: true,cerrmsg})

        }
        else{
          this.setState({cerrmsg : null})
        }
      })
    //   get dynamic height for buffet-wrapper/
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset=wrapper.offsetTop
        wrapper.style.height= 'calc(100vh - '+offset+'px - 40px)'

}
//this is for showing modal and it recieves type of modal "add" or "edit"
    handleShowModal=(type,id,item)=>{
        this.setState({showModal:true,typeModal:type,selectedId:id?id:'',selectedItem:item?item:'',errmsg:''})
    }
    //closing modal and reset selectedId
    handleCloseModal=()=>{
        this.setState({showModal:false,typeModal:'',selectedId:''})
    }
    //create new category request
    handleCreate = (data)=>{
        let errmsg=this.state.errmsg
        errmsg='';
        const categories = this.state.categories;
        axios.post(`${serverPath}/api/Category`,
        //here we used data to collect multiparts form with image 
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            categories.push(response.data.data)
            this.setState({categories})
            this.setState({showModal:false,errmsg:'',show:false})
            this.showAlertSuccess()
        }, (error) => {
            if(error.response.status === 403){
                //if user doesn't have permission redirect to no permission page 
                this.props.history.push('/nopermission')
             }
             if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
            this.setState({errmsg : null})
            }
        })
        
    }
    // edit current category
    handleEdit = (data,id)=>{
        const categories= this.state.categories
        let errmsg=this.state.errmsg
        axios.post(`${serverPath}/api/update_category/${id}`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            //this to close modal
            this.setState({editModal:false})
            for (let i in categories) {
                //here we update category object which we have updated
                if (categories[i].id == id) {
                    categories[i]='';
                    categories[i]=response.data.data
                break; //Stop this loop, we found it!

                }
                this.setState({categories})
            } 
            this.setState({showModal:false})
            //sweet alert success
            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
            this.setState({errmsg : null})
            }
        });
    }
    //delete category
    handleDelete=(id)=>{
        let errmsg =this.state.errmsg
        const headers=   {
            'Authorization':'Bearer '+this.props.token,
            'Accept':'application/json'
        }
       
        axios.delete(`${serverPath}/api/Category/${id}`, {headers: headers}).then((response)=>{

            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
              this.setState({errmsg : null})
            }
          });
          //delete category realtime
        const categories = this.state.categories.filter(category=>{
            return category.id !== id
        })
        this.setState({categories,alertDelete:''})
    }  
    // closing sweetalert for deleting alert
    onCancel = ()=>{
        this.setState({alertDelete:''})
    }
    // closing sweetalert for success
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    // init sweetalert for success 

    showAlertSuccess=()=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title="تم تنفيذ طلبك بنجاح !"
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
    //init sweetalert for deleting category
    showAlertDelete=(id,categoryname)=>{
        this.setState({
            alertDelete:<SweetAlert
            danger
            showCancel
            confirmBtnText="حذف"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            cancelBtnText='رجوع'
            title= {  ' هل انت متأكد من مسح ' + (categoryname) + ' ؟ '}
            onConfirm={()=>this.handleDelete(id)}
            onCancel={this.onCancel}
          >
          </SweetAlert>
        })
    }
    //showing errors
    setShow = ()=>{
        const show = false
        this.setState({show})
      }
    render(){
        const permissions=this.props.permissions;
        //categories listing
        const categories=this.state.categories.length>0 ? this.state.categories.map(category=>{
            return(
                <Col xl={3} lg={4} md={6} xs={12} key={category.id}>
                    <div className='item-card'>
                        <div className='item-quantity'>
                            <img src={category.image ? category.image : itemImg} alt={category.name} title={category.name}/>
                        </div>
                        <h4>{category.name}</h4>
                       
                        <div className='record-control'>
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'edit-category'
                                            ? (
                                                <Button  variant="success" onClick={()=>this.handleShowModal("edit",category.id,category)}><FontAwesomeIcon  icon={faPen}/></Button>

                                            )
                                            : (null))
                                    }))
                                    : null}
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'delete-category'
                                            ? (
                                                <Button  variant="danger" onClick={()=>this.showAlertDelete(category.id,category.name)}><FontAwesomeIcon icon={faTrash}/></Button>

                                            )
                                            : (null))
                                    }))
                                    : null}
                        </div>
                    </div>
                   
                </Col>
            )
        }):(<Col>لا توجد بيانات</Col>)
       
        return(
            <div className='page-content'>
                 <div className='page-header'>
                    <Container fluid>
                        <Row>
                        {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-category'
                                        ? (
                                            <Col xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal("add")}>أضف قسم جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>                   
                </div>
                <Container fluid>
                    <div className='buffet-wrapper'>
                    <Planets customLoading={this.state.loading} />

                        <Row className='stretch dir-col'>
                            {categories}
                        </Row>
                    </div>
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>
                    {this.state.typeModal == 'add' ? 'إضافة تصنيف جديد':'تعديل تصنيف'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CategoryForm 
                        type={this.state.typeModal}
                        category={this.state.selectedItem} 
                        createCategory={this.handleCreate} 
                        editCategory={this.handleEdit} 
                        errmsg={this.state.errmsg}
                        show={this.state.show}
                        setShow={this.setShow}
                        />
                    </Modal.Body>
              
                </Modal>
                {this.state.cerrmsg ?(
                    
                        <Alert show={this.state.show} variant="danger" dismissible onClose={this.setShow}>
                        
                            <span className='cursor' onClick={this.handleLogout}>{this.state.cerrmsg}</span>
                        </Alert>) : null} 
                    </Container>
                    {this.state.alertDelete}
                  {this.state.alertSuccess}
            </div>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        permissions:state.user.permissions

    }
}

export default connect(mapStateToProps) (ItemsList)