import React ,{useEffect} from 'react';
import dashboardLogo from '../../assets/img/Logo.svg'
import buffetIcon from '../../assets/img/buffet-icon.svg'
import dashboardIcon from '../../assets/img/dashboard-icon.svg'
import myordersIcon from '../../assets/img/myorders-icon.svg'
import usersIcon from '../../assets/img/users-icon.svg'
import usersControl from '../../assets/img/user-control.svg'
import addOrderImg from '../../assets/img/add-order-btn.svg'
import addorderbtn from '../../assets/img/addorderbtn.svg'
import myItems from '../../assets/img/my_items.svg'
import employeesItems from '../../assets/img/employees_items.svg'
import { NavLink} from 'react-router-dom'
import { Nav,Button,Dropdown ,DropdownButton} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes ,faPowerOff} from '@fortawesome/free-solid-svg-icons'
import {connect} from 'react-redux'
import './layout.scss'
    
    const SideBar = (props)=>{

    useEffect(() => {
        // socket for points 
        //read about socket io for react 
            // const channel = window.Echo.channel("channel-user-points");
            // channel.listen(".user-points-event", data=> {
            // if(data.user_id){
            //     if(data.user_id==props.id){props.Points(data.points)}
            // }
            // else{
            //     props.Points(data.points)
            // }
            // });
                    // socket for permissions when permissions changed it will affect directly 

            // const channelPermissions=window.Echo.channel("channel-user-permissions");
            // channelPermissions.listen(".user-permissions-event", data=> {
                
            //     if(props.usertype==data.user_type_id){props.Permissions(data.permissions)}
                
            // });
        });
        


        const usertype=props.usertype
        const permissions=props.permissions ? props.permissions : []
        const handleHideMenu= ()=>{
        props.toggleMenu()
        }
        const openBufeetMenu = ()=>{
            window.location.href="buffet-menu"
        }
        let className = 'sidebar';
        if (props.show==true) {
        className += ' showMenu';
        }
        const points=props.credit=='open'?(
        <div  className='points-container'>
            <h6>الرصيد الحالي : </h6> <span>مفتوح</span>
        </div>
        ):(
        <div className='points-container'>
            <h6>الرصيد الحالي : </h6> <label>{props.points}</label> <span>نقطة</span>
        </div>
        )
        return(
        <aside id='sidemenu' className={className} >
                <div className="sidebar-dropup" onClick={handleHideMenu}></div>
            <div className='sidebar-header mv'>
                <span>القائمة</span>
                <Button variant="close" onClick={handleHideMenu}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
            <div className='addOrder-box mv clearfix'>
            <NavLink activeClassName='ordermenu' onClick={props.toggleMenu} to="/buffet-menu" className='btn btn-primary'>
                <img src={addorderbtn} alt='dimofinf buffet'/>
                        أضف طلب جديد
            </NavLink>
                    
                    <Dropdown className='btn-userlogout user-menu'>
                        <Dropdown.Toggle variant="usermenu" id="dropdown-basic">
                            <img src={props.profilePic ?  props.profilePic:props.defaultImage} alt='Dimofinf Buffet' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <span className='arrowUp'></span>
                            <Dropdown.Item onClick={props.showalert}>
                                <FontAwesomeIcon icon={faPowerOff}/>
                                <span>تسجيل خروج </span>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
            </div>
            <div className='sidebar-header cv'>
                <NavLink to='/' className='buffet-logo'>
                    <img src={dashboardLogo} alt="Dimofinf Buffet"/> <span>ديموفنف بوفية</span>
                </NavLink>
            </div>
            <Nav className="main-menu">
                {permissions.includes('show-user')||permissions.includes('settings')||permissions.includes('show-department')||permissions.includes('show-usertype')||permissions.includes('pre-reasons')||permissions.includes('all-orders')||usertype==1?(
                    <>
                        <DropdownButton id="dropdown-basic-button" title={<><img src={dashboardIcon} alt='لوحة التحكم'/> <span>لوحة التحكم</span></>}>
                        <h3 className='title-control'>
                            <img src={usersControl} alt='إدارة المستخدمين'/> <span>إدارة المستخدمين</span> 
                        </h3>
                        {/* check if user have permission to show item */}
                        {/* need to know which permissions should user take you can check the component's requests */}
                        {permissions.includes('show-department')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/departments">ادارة الاقسام</NavLink>):null}
                        {permissions.includes('show-usertype')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/usersgroups">ادارة المجموعات</NavLink>):null}
                        {(permissions.includes('show-user')&&permissions.includes('show-usertype')&&permissions.includes('show-department'))||usertype==1?(<NavLink onClick={props.toggleMenu} to="/users">ادارة الافراد</NavLink>):null}
                        {permissions.includes('settings')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/sitesettings">ادارة الموقع</NavLink>):null}
                        {permissions.includes('pre-reasons')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/pre-reasons">ادارة التعليقات</NavLink>):null}
                        {permissions.includes('all-orders')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/admin-history">طلبات البوفيه</NavLink>):null}
                </DropdownButton>
                    </>
                ):null
                
                }
        
                <DropdownButton id="dropdown-basic-button2" title={<><img src={usersIcon} alt='الصفحة الشخصية'/> <span>الصفحة الشخصية</span></>}>
                    <h3 className='title-control'>
                        <img src={usersControl} alt='إدارة الحساب'/> <span>ادارة الحساب</span> 
                    </h3>
                        <NavLink onClick={props.toggleMenu} to="/profile">البيانات الشخصية</NavLink>
                        <NavLink onClick={props.toggleMenu} to="/favourites">قائمة المفضلة</NavLink>
                </DropdownButton>
                {permissions.includes( 'show-presit')||permissions.includes( 'show-item')||permissions.includes( 'show-category')||usertype==1?
                    (<>
                <DropdownButton id="dropdown-basic-button2" title={<><img src={buffetIcon} alt='قائمة البوفيه'/> <span>قائمة البوفيه</span></>}>
                    <h3 className='title-control'>
                        <img src={usersControl} alt='إدارة البوفيه'/> <span>ادارة البوفيه</span> 
                    </h3>
                    {permissions.includes('show-category')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/categories">ادارة الاقسام</NavLink>):null}
                    {permissions.includes('show-items')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/items">ادارة المنتجات</NavLink>):null}
                        
                    {permissions.includes('show-presit')||usertype==1?(<NavLink onClick={props.toggleMenu} to="/presets">ادارة المشروبات</NavLink>):null}  
                </DropdownButton>
                    </>):null
                }
                <NavLink onClick={props.toggleMenu} to="/history">
                    <img src={myordersIcon} alt='لوحة التحكم'/> <span>طلباتي</span> 
                </NavLink>               
                {props.usertype==1?(<NavLink onClick={props.toggleMenu} to="/employeesitems">
                    <img src={employeesItems} alt='مخزون الموظفين'/> <span>مخزون الموظفين</span> 
                </NavLink>  )
                :null}                
                <NavLink onClick={props.toggleMenu} to="/myItems">
                    <img src={myItems} alt='مخزوني الخاص'/> <span>مخزوني</span> 
                </NavLink>                
                <NavLink to="/myOrders">
                    <img src={myItems} alt='طلبات خارجية'/> <span>طلبات خارجية</span> 
                </NavLink>                
            </Nav>
            {points}
            <div className='addOrder-box cv'>
            <NavLink activeClassName='ordermenu' onClick={props.toggleMenu} to="/buffet-menu" className='btn btn-primary'>
                <img src={addorderbtn} alt='dimofinf buffet'/>
                        أضف طلب جديد
            </NavLink>
                <img src={addOrderImg} alt='dimofinf buffet'/>
            </div>
        </aside>
    )
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        permissions:state.user.permissions,
        profilePic:state.user.profilePic,
        points:state.user.points,
        credit:state.user.credit,
        id:state.user.id,
        usertype:state.user.usertype
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{     
      Points: (points)=>dispatch({type:'POINTS', points:points}),
      Permissions:(permissions)=>dispatch({type:'PERMISSIONS',permissions:permissions}),

    }
  }
export default connect(mapStateToProps,mapDispatchToProps) (SideBar)

