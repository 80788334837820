import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import { faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons'
import itemImg from '../../assets/img/default.png'

export default function EmployeeConsumedPoints(props) {
    const [order,setOrder]=useState(true)
    const check= props.data && props.data.length>0;//return true or false
    //getting top consumed points

    const getTopConsumed =  check?props.data.map((item,index)=>{
        return(
            <li className='order-item' key={index}>
                <div className="right">
                    <img src={item.profile_pic?item.profile_pic:itemImg} />
                    <span>{item.fullname}</span>
                </div>
                {/* checking if user already consumed points or not  */}
                <span>{item.spent_points ? item.spent_points : 0} نقطة</span>
            </li>
        )
    }):(<li className='order-item'>لا توجد بيانات حتى الان </li>)
    return (
        <div className='statistics-panel panel-danger'>
            <div className="panel-heading">
                <h3>استهلاك الموظفين للنقاط</h3>
                {check?(
                    // toggling order of consumed points
                <button className='btn'  onClick={()=>setOrder(!order)}>
                    الترتيب 
                    <div className="panel-icon">
                        <FontAwesomeIcon className={order?'active':''} icon={faCaretUp}/>
                        <FontAwesomeIcon className={order?'':'active'} icon={faCaretDown}/>
                    </div>
                    
                </button>):(null)}
            </div>
            <ul>
                {/* using .reverse for reversing the array */}
                {order?getTopConsumed:getTopConsumed.reverse()}
            </ul>
            
           
            
        </div>
    )
}

