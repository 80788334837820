import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import { faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function ConsumedItems(props) {
    const [order,setOrder]=useState(true)
    const checkTop= props.top && props.top.length>0; //return true or false
    const checkLow=props.low && props.low.length>0;//return true or false
    //getting top consumed items
    const getTopConsumed =  checkTop?props.top.map((item,index)=>{
        return(
            <li className='order-item' key={index}>
                <span className='order-number'>{index+1}</span>
                <span>{item.name}</span>
            </li>
        )
    }):(<li className='order-item'>لا توجد بيانات حتى الان </li>)
        //getting low consumed items

    const getLowestConsumed =  checkLow?props.low.map((item,index)=>{
        return(
            <li className='order-item' key={index}>
                <span className='order-number'>{props.low.length-index}</span>
                <span>{item.name}</span>
            </li>
            
        )
    }):(<li className='order-item'>لا توجد بيانات حتى الان </li>)
    return (
        <div className='statistics-panel panel-red'>
            <div className="panel-heading">
                <h3> الوحدات المستخدمة</h3>
                {(order&&checkTop)||(order==false&&checkLow)?(<button className='btn' onClick={()=>setOrder(!order)}>
                    الترتيب 
                    <div className="panel-icon">
                        {/* toggeling active state of arrows */}
                        <FontAwesomeIcon className={order?'active':''} icon={faCaretUp}/>
                        <FontAwesomeIcon className={order?'':'active'} icon={faCaretDown}/>
                    </div>
                    
                </button>):null}
                
                
            </div>
            <ul>
                {order?getTopConsumed:getLowestConsumed}
            </ul>
            
           
            
        </div>
    )
}

