import React, { Component } from 'react';
import axios from 'axios';
import serverPath from '../config';
import Modal from 'react-bootstrap/Modal'
import { Button,Alert ,Form,Container} from 'react-bootstrap';
import {connect} from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons'
import FormData from 'form-data'
import Planets from 'react-preloaders/lib/Planets/Planets';


class SiteSettings extends Component{
    constructor(props){
        super(props);
        this.state={
           settings:'',
            editable:'',
            showModal:false,
            curr_value:'',
            curr_label:'',
            showAlert:false,
            alertSuccess:'',
            errmsg:'',
            cerrmsg:'',
            type:'',
            loading:true
    }   
    this._isMounted=false
}

    async  componentDidMount(){
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset=wrapper.offsetTop
        wrapper.style.height= 'calc(100vh - '+offset+'px - 40px)'
        this._isMounted=true
        let cerrmsg=this.state.cerrmsg
        if(this._isMounted){
        await axios.get(`${serverPath}/api/SiteSettings`, {
         headers: {
             'Accept': 'application/json',
             'Authorization':'Bearer '+this.props.token
         }
     })
     .then((response) => {
            this.setState({
                settings:response.data.data,
                loading:false
            })
            
     },(error)=>{
        if(error.response.status === 403){
            this.props.history.push('/nopermission')
         }
         
         if (error.response) {
            error.response.data.errors.map(err => (cerrmsg += err + " . "))
            this.setState({showAlert: true,cerrmsg})
        }
         else{
           this.setState({errmsg : null})
         }
       })
    }
 }
    handleCloseModal=()=>{
        this.setState({showModal:false,errmsg:null})
    }
    //here sending key, value , label , type to unifiy edit function for all settings 
    handleEditModal=(key,value,label,type)=>{
        this.setState({editable:key,showModal:true,curr_value:value,curr_label:label,type:type})
    }
    handleChange=(e)=>{
        this.setState({curr_value:e.target.value})
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        const data = new FormData(document.querySelector('#sitesettings'));
        let errmsg=this.state.errmsg
        axios.post(`${serverPath}/api/SiteSettings`,data,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Accept':'application/json'
            }
          }).then((response)=>{
            this.setState({showModal:false})

            this.setState({
                settings:response.data.data,
               
            })
            this.showAlertSuccess()
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({showAlert: true,errmsg})
        
                }
            
            else{
              this.setState({errmsg : null})
            }
          });
    }
    showAlertSuccess=()=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title="تم تنفيذ طلبك بنجاح !"
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    setShow=()=>{
        this.setState({showAlert:false})
    }
    render(){
       
        return(
            
            <div className='page-content'>
                <Container fluid>
                    <div className='buffet-wrapper sitesettings'>
                        <Planets customLoading={this.state.loading}/>
                        <h4 className='title'>إعدادات الموقع</h4>
                        <ul className='collections'>
                            <li className='collection-item'>
                                <span>
                                    اسم الموقع
                                </span>
                                <label>
                                    {this.state.settings.site_title}
                                </label>
                                <div className='record-control'>
                                    <Button onClick={()=>this.handleEditModal('site_title',this.state.settings.site_title,'إعدادات الموقع','text')} variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                    عدد مرات الدخول
                                </span>
                                <label>
                                    {this.state.settings.login_number}
                                </label>
                                <div className='record-control'>
                                    <Button onClick={()=>this.handleEditModal('login_number',this.state.settings.login_number,'عدد مرات الدخول','number')} variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                    عدد العناصر المعروضة
                                </span>
                                <label>
                                    {this.state.settings.paginator}
                                </label>
                                <div className='record-control'>
                                    <Button onClick={()=>this.handleEditModal('paginator',this.state.settings.paginator,'عدد العناصر المعروضة','number')} variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                    نقاط الاعضاء
                                </span>
                                <label>
                                    {this.state.settings.user_points}
                                </label>
                                <div onClick={()=>this.handleEditModal('user_points',this.state.settings.user_points,'نقاط الاعضاء','number')} className='record-control'>
                                    <Button  variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                    موعد إغلاق البوفيه
                                </span>
                                <label>
                                    {this.state.settings.last_order}
                                </label>
                                <div onClick={()=>this.handleEditModal('last_order',this.state.settings.last_order,'موعد إغلاق البوفيه','time')} className='record-control'>
                                    <Button  variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                   مدة الطلب (بالدقائق)
                                </span>
                                <label>
                                    {this.state.settings.order_period}
                                </label>
                                <div onClick={()=>this.handleEditModal('order_period',this.state.settings.order_period,'مدة الطلب','number')} className='record-control'>
                                    <Button  variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                            <li className='collection-item'>
                                <span>
                                    مدة الايقاف (بالساعات)
                                </span>
                                <label>
                                    {this.state.settings.freezing_period/60}
                                </label>
                                <div onClick={()=>this.handleEditModal('freezing_period',this.state.settings.freezing_period/60,'مدة الايقاف','number')} className='record-control'>
                                    <Button  variant="success">
                                    <FontAwesomeIcon  icon={faPen}/>
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {this.state.cerrmsg && this.state.showAlert?(
                  
                  <Alert show={this.state.showAlert} variant="danger" dismissible onClose={this.setShow}>
                  
                      <Alert.Link>{this.state.cerrmsg}</Alert.Link>
                  </Alert>) : null}  
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                        <Modal.Title>تعديل {this.state.curr_label}  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form id='sitesettings' className='text-center' onSubmit={this.handleSubmit}>
                            <Form.Group controlId="addDepartment">
                                <Form.Control pattern={this.state.type=='text'?"[أ-يa-zA-Z\s]+":''} required type={this.state.type} name={this.state.editable} value={this.state.curr_value} min='0' onChange={this.handleChange}/>
                            </Form.Group>
                            {this.state.errmsg && this.state.showAlert?(
                  
                            <Alert show={this.state.showAlert} variant="danger" dismissible onClose={this.setShow}>
                            
                                <Alert.Link>{this.state.errmsg}</Alert.Link>
                            </Alert>) : null}  
                            <Button  variant="primary" type="submit">
                                حفظ
                            </Button>
                        </Form>
                            
                        </Modal.Body>
              
                    </Modal>
                </Container>
            {this.state.alertSuccess}
        </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        permissions:state.user.permissions,
        usertype:state.user.usertype,
    }
}

export default connect(mapStateToProps) (SiteSettings)
