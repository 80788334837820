import React, { Component } from 'react'
import { Button ,Modal, Container,Row,Col,Form , Alert } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import serverPath from '../config';
import {connect} from 'react-redux';
import HistoryDetailsCard from './HistoryDetailsCard';
import axios from 'axios'
import { Planets } from 'react-preloaders';
import itemImg from '../../assets/img/defaultItem.png'

const animatedComponents = makeAnimated();


class HistoryDetails extends Component{
    state={
        rate:'',
        review:'',
        cartItems:[],
        loading:true,
        showModal:false,
        selectedOrder:[],
        orderPreset:[],
        specialOrder:'',
        type:'',
        assignEmployee:'',
        orderOwner:'',
    }
    async componentDidMount(){
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset=wrapper.offsetTop
        wrapper.style.height= 'calc(100vh - '+offset+'px - 40px)'
        let cerrmsg = this.state.cerrmsg
        const cart_id=this.props.match.params.id
        await axios.get(`${serverPath}/api/order_details/${cart_id}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            const cartItems= response.data.order.order_details?response.data.order.order_details:null;
            const specialOrder= response.data.order.details?response.data.order.details:null;
            const assignEmployee= response.data.order.employee?response.data.order.employee:null;
            // const orderOwner= response.data.order.rating&&response.data.order.rating.user.fullname?response.data.order.rating.user.fullname:null;
           this.setState({loading:false,cartItems,specialOrder,assignEmployee})
        },(error)=>{
            if(error.response.status === 403){
                this.props.history.push('/nopermission')
             }
           
            if (error.response) {
                error.response.data.errors.map(err => (cerrmsg += err + " . "))
                this.setState({show: true,cerrmsg,loading:false})
    
            }
            else{
              this.setState({cerrmsg : null})
            }
          })
    
    }
    show=(type,orderDetails,order_preset)=>{
        this.setState({type,showModal:true,selectedOrder:orderDetails,orderPreset:order_preset})
        
    }
    handleCloseModal=()=>{
        this.setState({showModal:false})
    }
    render(){
        const carts = this.state.cartItems.length>0?(
            this.state.cartItems.map(item=>{
                return(
                    <div onClick={()=>this.show("normal",item)} className='cart-row' key={item.id}>
                        <span className='item-name'>
                            <img src={item.order_preset.image?item.order_preset.image:itemImg} className="cartItem-img"/>
                            {item.order_preset.name?item.order_preset.name:'تم مسح العنصر'}
                        </span>
                    </div>
                )              
            })
        ):(this.state.specialOrder?(<div onClick={()=>this.show("special",this.state.specialOrder)} className='cart-row' key={this.props.match.params.id}>
        <span className='item-name'>
            
            طلب خاص
        </span>
    </div>):(<div className='cart-row noitems'>
        لم تقوم بإضافة اي طلبات حتى الان
    </div>))



            return(
                <div className='page-content'>

                    <Container fluid>
                        <div className='buffet-wrapper cart'>
                            <Planets customLoading={this.state.loading} />
                            <Col xs={12}>
                                {/* {this.state.assignEmployee?<h2>مقدم الطلب : {this.state.orderOwner}</h2>:null} */}
                                {this.state.assignEmployee?<h3>موظف البوفيه : {this.state.assignEmployee}</h3>:null}
                                <div className='cart-content'>
                                    {carts}
                                </div>
                            </Col>
                        </div>
                        <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body >
                            <HistoryDetailsCard 
                            selectedOrder={this.state.selectedOrder}
                            type={this.state.type}
                        />
                        </Modal.Body>
              
                </Modal>
                    </Container>
                </div>
             )   
        }
    }
                
    const mapStateToProps=(state)=>{
        return{
            token:state.auth.token,
            usertype:state.user.usertype,
            id:state.user.id
        }
    }
    
    export default connect(mapStateToProps) (HistoryDetails)