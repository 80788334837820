import React, { Component } from 'react'
import { Button , Modal,Alert , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import itemImg from '../../assets/img/default.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemForm from './ItemForm'
import { faTrash ,faPen,} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import SearchBar from '../layout/SearchBar'
import Pagination from '../dashboard/Pagination'

class ItemsList extends Component{
    state={
        items:[],
        showModal:false,
        typeModal:'',
        selectedId:'',
        selectedItem:'',
        currentPage:1,
        errmsg:'',
        cerrmsg:'',
        show:'',
        alertDelete:'',
        alertSuccess:'',
        loading:true,
        request:''
    }
   async componentDidMount(){
        await this.fillData(this.state.currentPage)
        // const wrapper=document.querySelector(".buffet-wrapper")
        // const offset = wrapper.offsetTop
        // const paginationHeight=document.querySelector(".pagination").style.height
        // wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`

}
    fillData=(page)=>{
        let cerrmsg=this.state.cerrmsg
        axios.get(`${serverPath}/api/Item?page=${page}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            const items= response.data.data.data;
            const request = response.data.data;
        this.setState({items,loading:false,request}) 
        },(error)=>{
            if(error.response.status === 403){
                this.props.history.push('/nopermission')
            }
            if (error.response) {
                error.response.data.errors.map(err => (cerrmsg += err + " . "))
                this.setState({show: true,cerrmsg,loading:false})
    
            }
            else{
            this.setState({cerrmsg : null})
            }
        })
    }
    goToPage =(page)=>{
        this.fillData(page)
        this.setState({currentPage:page})
    }
    handleShowModal=(type,id,item)=>{
        this.setState({showModal:true,typeModal:type,selectedId:id?id:'',selectedItem:item?item:''})
    }
    handleCloseModal=()=>{
        this.setState({showModal:false,typeModal:'',selectedId:'',errmsg:''})
    }
    handleCreate = (data)=>{
        const items = this.state.items;
        let errmsg=this.state.errmsg
        errmsg=''
        axios.post(`${serverPath}/api/Item`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            if(this.state.request.last_page <= 1){

                items.push(response.data.data)
            }
            this.setState({items})
            this.setState({showModal:false})
            this.showAlertSuccess()
        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
            this.setState({errmsg : null})
            }
        })
        
    }
    handleEdit = (data,id)=>{
        const items= this.state.items
        let errmsg= this.state.errmsg
        axios.post(`${serverPath}/api/update_item/${id}`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            this.setState({editModal:false})
            for (let i in items) {
                
                if (items[i].id == id) {
                    items[i]='';
                    items[i]=response.data.data
                break; //Stop this loop, we found it!

                }
                this.setState({items})
            } 
            this.setState({showModal:false})

            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
            this.setState({errmsg : null})
            }
        });
    }
    handleDelete=(id)=>{
        let cerrmsg=this.state.cerrmsg
        const headers=   {
            'Authorization':'Bearer '+this.props.token,
            'Accept':'application/json'
        }
        const items = this.state.items.filter(item=>{
            return item.id !== id
        })
        this.setState({items,alertDelete:''})
        axios.delete(`${serverPath}/api/Item/${id}`, {headers: headers}).then((response)=>{

            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (cerrmsg += err + " . "))
                this.setState({show: true,cerrmsg})
    
            }
            else{
              this.setState({cerrmsg : null})
            }
          });
    }  
    onCancel = ()=>{
        this.setState({alertDelete:''})
    }
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    showAlertSuccess=()=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title="تم تنفيذ طلبك بنجاح !"
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
    showAlertDelete=(id,itemname)=>{
        this.setState({
            alertDelete:<SweetAlert
            danger
            showCancel
            confirmBtnText="حذف"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            cancelBtnText='رجوع'
            title= {  ' هل انت متأكد من مسح ' + (itemname) + ' ؟ '}
            onConfirm={()=>this.handleDelete(id)}
            onCancel={this.onCancel}
          >
          </SweetAlert>
        })
    }
    setShow = ()=>{
        const show = false
        this.setState({show,errmsg:'',cerrmsg:''})
      }
    render(){
        if(this.state.loading == false){
            const wrapper=document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight=this.state.request.last_page >1?'87px':'32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`
            
        }
        const permissions=this.props.permissions;

        const items=this.state.items.length>0  ? this.state.items.map(item=>{
            return(
                <Col xl={3} lg={4} md={6} xs={12} key={item.id}>
                    <div className='item-card'>
                        <div className='item-quantity'>
                            <img src={item.image ? item.image : itemImg} alt={item.name} title={item.name}/>
                            {/* this component for items quantity bar around item */}
                            <CircularProgressbar
                             styles={{path: {
                                // Path color "#F76666" "#DCDEF4"
                                stroke: `${item.alert_quantity>=item.remain_quantity  ? '#F76666' :'#DCDEF4' }`},trail: {
                                // Trail color
                                stroke: '#F0F0F0'} }}
                                 value={item.remain_quantity/item.quantity}
                                  maxValue={1} />
                        </div>
                        <h4>{item.name}</h4>
                        <span>{(item.remain_quantity/item.quantity)*100+'%'}</span>
                        <span>النقاط : {item.credit_per_spoon} نقطة</span>
                        <div className='record-control'>
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'edit-item'
                                            ? (
                                                <Button key={Math.random()} variant="success" onClick={()=>this.handleShowModal("edit",item.id,item)}><FontAwesomeIcon  icon={faPen}/></Button>
                                            )
                                            : (null))
                                    }))
                                    : null}
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'delete-item'
                                            ? (
                                                <Button key={Math.random()} variant="danger" onClick={()=>this.showAlertDelete(item.id,item.name)}><FontAwesomeIcon icon={faTrash}/></Button>

                                            )
                                            : (null))
                                    }))
                                    : null}
                        </div>
                    </div>
                   
                </Col>
            )
        }):(<Col>لا توجد بيانات</Col>)
        
        return(
            <div className='page-content'>
                 <div className='page-header'>
                    <Container fluid>
                        <Row>
                        {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-item'
                                        ? (
                                            <Col key={Math.random()} xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal("add")}>أضف منتج جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>                   
                </div>
                <Container fluid>
                    <div className='buffet-wrapper'>
                        <Planets customLoading={this.state.loading}/>
                        <Row className='stretch'>
                            {items}
                        </Row>

                    </div>
                    {this.state.request.last_page >1?
                            <Pagination  goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page}/>  :null
                        }
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>
                    {this.state.typeModal == 'add' ? 'إضافة منتج جديد':'تعديل منتج'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ItemForm 
                        type={this.state.typeModal}
                        item={this.state.selectedItem} 
                        createItem={this.handleCreate} 
                        editItem={this.handleEdit} 
                        errmsg={this.state.errmsg}
                        show={this.state.show}
                        setShow={this.setShow}
                        />
                    </Modal.Body>
              
                </Modal>
                {this.state.cerrmsg ?(
                    
                    <Alert show={this.state.show} variant="danger" dismissible onClose={this.setShow}>
                     
                        <span className='cursor' onClick={this.handleLogout}>{this.state.cerrmsg}</span>
                    </Alert>) : null} 
                </Container>
                {this.state.alertDelete}
                  {this.state.alertSuccess}
            </div>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        permissions:state.user.permissions

    }
}

export default connect(mapStateToProps) (ItemsList)