import React, { Component } from 'react'
import { Container , Alert , Col ,Form,Modal,Button} from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import defaultImage from '../../assets/img/default.png'
import { faCamera,faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';

class Profile extends Component{
    state={
        id:'',
        username:'',
        fullname:'',
        email:'',
        password:'',
        dep_id:'',
        type_id:'',
        credit:'',
        profile_pic:'',
        errmsg:'',
        cerrmsg:'',
        editImg:false,
        editPassword:false,
        alertSuccess:'',
        loading:true,
        show:''
    }
   async componentDidMount(){
    const wrapper=document.querySelector(".buffet-wrapper")
    const offset=wrapper.offsetTop
    wrapper.style.height= 'calc(100vh - '+offset+'px - 40px)'
    let cerrmsg = this.state.cerrmsg
    cerrmsg = ''
    await axios.get(`${serverPath}/api/my_profile/${this.props.id}`, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
       this.setState({
           id:response.data.data.id,
           username:response.data.data.username,
           fullname:response.data.data.fullname,
           email:response.data.data.email,
           password:response.data.data.password,
           dep_id:response.data.data.dep_id,
           type_id:response.data.data.type_id,
           credit:response.data.data.credit,
           profile_pic:response.data.data.profile_pic,
           points:response.data.data.points,
           loading:false
        })
    },(error) => {
        if (error.response) {
            error.response.data.errors.map(err => (cerrmsg += err + " . "))
            this.setState({show: true,cerrmsg})

        }
        else{
          this.setState({errmsg : null})
        }
      });
      
}
showAlertSuccess=()=>{
    this.setState({
        alertSuccess:<SweetAlert
        success
        confirmBtnText="تم"
        confirmBtnBsStyle="primary"
        title="تم تنفيذ طلبك بنجاح !"
        timeout={2000}
        onConfirm={this.onCancelSuccess}
        onCancel={this.onCancelSuccess}
      >
      </SweetAlert>
    })
}
onCancelSuccess = ()=>{
    this.setState({alertSuccess:''})
}
editProfilePic=()=>{
    this.setState({
        editImg:true
    })
}
editPassword=()=>{
    this.setState({
        editPassword:true
    })
}
handleCloseModal = ()=>{
    this.setState({editImg:false,editPassword:false})
}
handleChangeImg =(e)=>{
    this.setState({profile_pic:e.target.files[0].name})
}
handleChangePassword =(e)=>{
    this.setState({password:e.target.value})
}
handleSubmit=(e)=>{
    e.preventDefault();
    const data = new FormData(document.querySelector('#editProfile'));
    let errmsg = this.state.errmsg
    errmsg = ''
    axios.post(`${serverPath}/api/update_profile/${this.state.id}`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            this.props.ChangeImg(response.data.data.profile_pic)
            this.setState({editImg:false})
            this.showAlertSuccess()
        },(error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
              this.setState({errmsg : null})
            }
          });
}
handleSubmitPassword=(e)=>{
    e.preventDefault();
    let errmsg = this.state.errmsg
    errmsg = ''
    const password=this.state.password
    axios.post(`${serverPath}/api/update_profile/${this.state.id}`,
            {
                password:password
            }
            ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Accept':'application/json'
            }
        }).then((response)=>{
            this.setState({editPassword:false})
            this.showAlertSuccess()
        },(error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
              this.setState({errmsg : null})
            }
          });
}
setShow = () => {
    const show = false
    this.setState({show})
}
    render(){
       
        return(
            <div className='page-content'>
                <Container fluid>
                    <div className='profile buffet-wrapper'>
                         <Planets customLoading={this.state.loading} />
                        <div className='profile-img-container'>
                            <img className='profile-img' alt={this.state.fullname} src={this.props.profilePic?  this.props.profilePic : defaultImage}/>
                            <div className="file" onClick={this.editProfilePic}>
                            <FontAwesomeIcon className='camera-icon' icon={faCamera}/>
                            </div>                                
                        </div>
                        <ul className='collections'>
                            <li className='collection-item '>
                                <span>إسم المستخدم</span>
                                <label>{this.state.username}</label>
                            </li>
                            <li className='collection-item '>
                                <span>الاسم كامل</span>
                                <label>{this.state.fullname}</label>
                            </li>
                            <li className='collection-item '>
                                <span>
                                البريد الالكتروني
                                </span>
                                <label>{this.state.email}</label>
                            </li>
                            <li className='collection-item '>
                            <span>
                                كلمة المرور
                                </span>
                                <div className='record-control'>
                                    <Button  variant="success" onClick={()=>this.editPassword()}><FontAwesomeIcon  icon={faPen}/></Button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </Container>
                <Modal animation={false} show={this.state.editImg} className='text-center' onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>تعديل صورة المستخدم</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id='editProfile' onSubmit={this.handleSubmit}>
                        <Form.Group className='relative'>
                        <input className='form-control ' type="text" disabled value={this.state.username ? this.state.username : 'صورة المستخدم'}/>
                        <div className="file btn btn-lg btn-primary col-auto">
                            تحميل
                            <input accept="image/x-png,image/gif,image/jpeg" type="file" onChange={this.handleChangeImg} name='profile_pic'/>
                        </div>                

                        </Form.Group>
                        <Button  variant="primary" type="submit">
                            حفظ
                        </Button>
                        </Form>
                    </Modal.Body>
                   
                </Modal>
                <Modal animation={false} show={this.state.editPassword} className='text-center' onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>تعديل كلمة المرور</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id='editPassword' onSubmit={this.handleSubmitPassword}>
                            <Form.Group className='relative'>
                                <input className='form-control' name='passsword' type="password" onChange={this.handleChangePassword}  placeholder='كلمة المرور' value={this.state.password}/>                                 
                            </Form.Group>
                            <Button  variant="primary" type="submit">
                                حفظ
                            </Button>
                        </Form>
                        {this.state.errmsg &&this.state.show?(
                  
                        <Alert show={this.state.show} variant="danger" dismissible onClose={this.state.setShow}>
                        
                            {this.state.errmsg}
                        </Alert>) : null} 
                    </Modal.Body>
                   
                </Modal>
                {this.state.alertSuccess}

            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        id:state.user.id,
        profilePic:state.user.profilePic,
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      ChangeImg: (profilePic)=>dispatch({type:'CHANGEIMG', profilePic:profilePic}),
  
    }
  }

export default connect(mapStateToProps,mapDispatchToProps) (Profile)