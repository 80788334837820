import React, { Component } from 'react'
import { Button , Alert,Modal , Container,Dropdown,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import Review from './Review'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes ,faStar,faSyncAlt,faAngleDown} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import Rating from 'react-rating'
import Moment from 'moment';
import { NavLink } from 'react-router-dom';

import Pagination from '../dashboard/Pagination'
import  { Redirect } from 'react-router-dom'

class OrderHistory extends Component{
    constructor(props){
        super(props);
        this.state={
            historyList:[],
            filteredList:[],
            filter:false,
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            errmsg:'',
            cerrmsg:'',
            show:'',
            alert:'',
            loading:true,
            filterText:null,
            reasons:'',
            request:'',
            currentPage:1,
            status:''
        
            
        }
    }
   async componentDidMount(){
       
    this.fillData(this.state.currentPage,'');
    // const wrapper=document.querySelector(".buffet-wrapper")
    // const offset = wrapper.offsetTop
    // const paginationHeight=document.querySelector(".pagination").style.height
    // wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`
      

}

fillData =(page,status)=>{
    this.setState({loading:true})
    Promise.all([
        axios.get(`${serverPath}/api/order_history/${this.props.id}?page=${page?page:1}&status=${status?status:''}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }),
        axios.get(`${serverPath}/api/all_pre_define_reason`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }),
        
    ])
    .then(([responseOne,responseTwo])=>{
        const historyList= responseOne.data.order?responseOne.data.order.data:[];
        const request= responseOne.data.order?responseOne.data.order:'';
        const reasons = responseTwo.data.data;
        this.setState({request,historyList,loading:false,reasons})  
    })
    .catch(errors => {
        this.setState({errmsg:errors.message})
        
    })
}


goToPage =(page)=>{
this.fillData(page,this.state.status)
this.setState({currentPage:page})
}
cancelOrder=(id)=>{

     axios.post(`${serverPath}/api/cancel_order`, {order_id:id,user_id:this.props.id},{
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const historyList =this.state.historyList.filter(item=>
            item.id==id?item.status='Cancelled':item.status
          )
       this.setState({historyList,loading:false})
    },(error)=>{
        if(error.response){
            this.setState({cerrmsg : error.response.data.message,loading:false,show:true
            })  
        }
        else{
          this.setState({cerrmsg : null})
        }
      })
}
filterOrders=(status,text)=>{


     this.setState({filterText:text,status})  
     this.fillData(1,status);  
}
viewAll=()=>{
    this.setState({filterText:null,status:''})
    this.fillData('','')
}
addReview=(id)=>{
    this.setState({selectedId:id,showModal:true})
}
handleCloseModal=()=>{
    this.setState({showModal:false})
}
setShow=()=>{
    this.setState({show:false})
}
submitReview=(id,review,rate,pre_id)=>{
    let errmsg = this.state.errmsg
    errmsg = ''
    axios.post(`${serverPath}/api/order_review`,{
        order_id:id,
        review:review,
        rate:rate,
        pre_define_id:pre_id

    }, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        this.setState({selectedId:'',showModal:false})
        this.showAlert('success','',response.data.message)
        this.fillData(this.state.currentPage,this.state.status)

    },(error)=>{

        
        if (error.response) {
            error.response.data.errors.map(err => (errmsg += err + " . "))
            this.setState({alert:'',loading:false,show: true,errmsg})

        }
        else{
          this.setState({errmsg : null})
        }
      })
}
onCancel = ()=>{
    this.setState({alert:''})
}
showAlert=(type,id,msg)=>{
    type=='reOrder'?
    this.setState({
        alert:<SweetAlert
        openAnim
        closeAnim
        warning
        showCancel
        reverseButtons
        confirmBtnText="تأكيد"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        cancelBtnText='إلغاء'
        title="هل انت متأكد من اعادة الطلب ؟"
        onConfirm={()=>this.reOrder(id)}
        onCancel={this.onCancel}
      >
    </SweetAlert>
    }):
    this.setState({
        alert:<SweetAlert
        success
        confirmBtnText="حسناً"
        confirmBtnBsStyle="success"
        title={msg}
        onConfirm={this.onCancel}
        onCancel={this.onCancel}
      >
    </SweetAlert>
    })
} 
reOrder=(id)=>{
    let errmsg = this.state.errmsg
    axios.post(`${serverPath}/api/re_order`,{order_id:id}, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        this.showAlert('success','',response.data.message)
        this.props.CartItems(response.data.data)
    },(error)=>{

        if (error.response) {
            error.response.data.errors.map(err => (errmsg += err + " . "))
            this.setState({show: true,errmsg,alert:'',loading:false})

        }
        else{
          this.setState({errmsg : null})
        }
      })
}
    render(){
        if(this.state.loading == false){
            const wrapper=document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight=this.state.request.last_page >1?'87px':'32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`
            
        }
        const historyList =this.state.historyList.length>0?this.state.historyList.map(item=>{
            return(
                <div className='order-row' key={item.id}>
                   <div className='order-info'>
                       <h4> 
                       <NavLink to={'/historydetails/'+item.id}>
                           طلب رقم #{item.id} 

                        </NavLink> 
                           <span className={`order-status `+item.status}>{item.status =='Pending'? 'قيد الانتظار' : item.status=='Completed'?'تم الاستلام':item.status=='Cancelled'?'تم الالغاء':"قيد التنفيذ"}</span>
                        </h4>
                        <span className='order-date'>{Moment(item.created_at).format("MMMM D YYYY , hh:mm A")}</span>

                   </div>
                    <div className='order-btns'>
                        {item.status =='Pending'?(
                            <Button variant='primary'  onClick={()=>this.cancelOrder(item.id)}>
                                <FontAwesomeIcon  className='order-icon' icon={faTimes}/> 
                                إلغاء الطلب
                            </Button>
                        ):(null)}
                        {item.status =='Completed'?(
                            <>
                            {item.rating? (
                                <Rating
                                className='rating'
                                readonly
                                initialRating={item.rating.rate}
                                emptySymbol={<FontAwesomeIcon className='empty-star' icon={faStar}/>}
                                fullSymbol={<FontAwesomeIcon className='full-star' icon={faStar}/>}
                                />
                            )
                            :(<Button variant='primary' onClick={()=>this.addReview(item.id)}>
                                <FontAwesomeIcon className='order-icon' icon={faStar}/> 
                                تقييم الطلب
                            </Button>)
                            
                        }
                            {item.type==='normal'?( <Button variant='primary' onClick={()=>this.showAlert('reOrder',item.id)}>
                                <FontAwesomeIcon className='order-icon' icon={faSyncAlt}/> 
                                إعادة الطلب 
                            </Button>):null}
                           
                            </>
                        ):(null)}
                        
                    </div>
                </div>
            )
        }):("عفوا لا توجد بيانات")
               return(
            <div className='page-content'>
                
                <Container fluid>
                    <div className='buffet-wrapper order-history'>
                    <Planets customLoading={this.state.loading} />
                    <div className='header-filter'>
                        <h1 className='title'>طلباتي</h1>   
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {this.state.filterText?this.state.filterText:"ترتيب حسب"}
                                <FontAwesomeIcon className='filter-arrow' icon={faAngleDown}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>this.viewAll()}>
                                    الكل                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.filterOrders("InProgress","قيد التنفيذ ")}>
                                    قيد التنفيذ                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.filterOrders("Pending","قيد الانتظار ")}>
                                    قيد الانتظار                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.filterOrders("Cancelled","تم الحذف ")}>
                                    تم الحذف                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.filterOrders("Completed","تم التسليم ")}>
                                    تم التسليم                                        
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>                     
                    </div>
                        {historyList}
                    </div>
                    {this.state.request.last_page >1?
                            <Pagination  goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page}/>  :null
                        }
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                               طلب رقم # {this.state.selectedId}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Review 
                                order_id={this.state.selectedId}
                                submitReview={this.submitReview}
                                reasons={this.state.reasons}

                            />
                             
                        </Modal.Body>
              
                </Modal>
                    {this.state.alert}
                    {this.state.errmsg && this.state.show?(
                  
                  <Alert  variant="danger" dismissible onClose={this.setShow}>
                   
                    {this.state.errmsg}
                  </Alert>) : null}   
                    </Container>
                </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        id:state.user.id

    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      CartItems: (cartItems)=>dispatch({type:'CARTITEMS', cartItems:cartItems}),  

    }
  }

export default connect(mapStateToProps,mapDispatchToProps) (OrderHistory)