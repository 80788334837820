import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAngleDoubleRight , faAngleDoubleLeft , faClock  , faAngleLeft , faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { render } from '@testing-library/react';

class  Pagination extends Component{
    state={
        current :this.props.currentPage
    }
      goToPage =(page)=>{
        this.setState({current:page})
        this.props.goToPage(page)
      }
    render(){
    let totalPages =[]
    for(let i=1;i<=this.props.totalPages;i++){
        totalPages.push(i)
    }
    const pages = totalPages.map((page,index)=>{
        return(
            <li key={index} className={this.props.currentPage === page ? `active`: ''} onClick={()=>this.goToPage(page)}>
                <button className='btn btn-primary'>
                    {page}
                </button>
            </li>

        )
    })
    // check first or last page 
    const isFirst =this.state.current  === 1 ? true : false
    const isLast = this.state.current  === this.props.totalPages ? true : false
    
    return(
        <ul className='pagination'>
              <li >
                <button  disabled={isFirst}
                            className={isFirst ? `disabled-page btn btn-primary`:`btn btn-primary`}  onClick={()=>this.goToPage(1)}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
            </li>
              <li >
                <button  disabled={isFirst}
                            className={isFirst ? `disabled-page btn btn-primary`:`btn btn-primary`}  onClick={()=>this.goToPage(this.state.current-1)}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </li>
            {pages}
            <li >
                <button disabled={isLast}
                             className={isLast ? `disabled-page btn btn-primary`:`btn btn-primary`} onClick={()=>this.goToPage(this.state.current+1)}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
            </li>
            <li >
                <button  disabled={isLast}
                             className={isLast ? `disabled-page btn btn-primary`:`btn btn-primary`} onClick={()=>this.goToPage(this.props.totalPages)}>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
            </li>

        </ul>
    )
}
}
export default Pagination
