import React, { Component } from 'react'
import { Planets } from 'react-preloaders';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import serverPath from '../config'
import { Container, Button } from 'react-bootstrap'
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';



class Notifications extends Component {
    state = {
        notifications: [],
        loading: true,
        show: '',
        alertDelete: '',
        alertSuccess: '',
    }
    componentDidMount() {
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        wrapper.style.height = 'calc(100vh - ' + offset + 'px - 40px)'
            //getting all notifications once you have logined

        axios.get(`${serverPath}/api/all_notifications`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
            }
        }).then(response => {
            const notifications = response.data.data
            this.setState({ notifications, loading: false })
        })
            //getting notifications real time function call 

        this.realTimeNotification()
    }
    readedNotifi = (id) => {
            //read notifications
        const notifications=this.state.notifications
        const headers = {
            'Authorization': 'Bearer ' + this.props.token,
            'Accept': 'application/json'
        }

        axios.post(`${serverPath}/api/read_notification`,{notification_id:id}, { headers: headers }).then((response) => {
            for (let i in notifications) {
                if (notifications[i].id == id) {
                    notifications[i].viewed = 1
                    break; //Stop this loop, we found it!
                }
            }
            this.setState({notifications})
        }, (error) => {
           
        });
        
    }
    realTimeNotification=()=>{
            //socket io notification channel usage

    //     window.Echo.channel('notification-channel').listen(".notification-event", data=> {
    //       const notifications=this.state.notifications
    //       const notifi =data
    //         //adding view key to append this object
    //       notifi.viewed=0
    //       if(!notifi.notification_id&&notifi.user_id==this.props.id){
    //           //put the newest notification item at the top of list ( first item)
    //         notifications.unshift(notifi)
    //         this.setState({notifications})
    //       }
          
    // });
      }
    removeNotifi = (id) => {
        const headers = {
            'Authorization': 'Bearer ' + this.props.token,
            'Accept': 'application/json'
        }
    //if we send id= all as a parameter it will delete all notifications otherwise it will delete single notifications

        axios.post(`${serverPath}/api/delete_notification`,
            id=="all"?
                {all:1}:{notification_id:id}
            
            
        , { headers: headers }).then((response) => {
            this.showAlertSuccess()
            if(id!=='all'){
                const notifications = this.state.notifications.filter(notification => {
                    return notification.id !== id
                })
                this.setState({ notifications, alertDelete: '' })
            }
            else{
                this.setState({ notifications:[], alertDelete: '' })
            }
        }, (error) => {
            if (error.response) {
                this.setState({ show: true })
            }
            else {
                this.setState({ errmsg: null })
            }
        });
        
    }
    showAlertSuccess = () => {
        this.setState({
            alertSuccess: <SweetAlert
                success
                confirmBtnText="تم"
                confirmBtnBsStyle="primary"
                title="تم تنفيذ طلبك بنجاح !"
                timeout={2000}
                onConfirm={this.onCancelSuccess}
                onCancel={this.onCancelSuccess}
            >
            </SweetAlert>
        })
    }
    showAlertDelete = (id) => {
        this.setState({
            alertDelete: <SweetAlert
                danger
                showCancel
                confirmBtnText="حذف"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="primary"
                cancelBtnText='رجوع'
                title={id=='all'?'هل انت متأكد من مسح جميع الاشعارات ؟':' هل انت متأكد من مسح الاشعار ؟ '}
                onConfirm={() => this.removeNotifi(id)}
                onCancel={this.onCancel}
            >
            </SweetAlert>
        })
    }
    onCancel = () => {
        this.setState({ alertDelete: '' })
    }
    onCancelSuccess = () => {
        this.setState({ alertSuccess: '' })
    }
    render() {
        //list notification 
        const notifications_list = this.state.notifications.length > 0 ? this.state.notifications.map((item, index) => {
            return (<div onClick={item.viewed==0?()=>this.readedNotifi(item.id):null} className={item.viewed==0?"notification-row":"notification-row bg-white"} key={index}>
                <div className='order-info'>
                    <h4>
                        {item.body}
                    </h4>
                    <span className='order-date'>
                        {/* using moment js to reformate date  */}
                        {Moment(item.created_at).format("MMMM D YYYY , hh:mm A")}
                    </span>
                </div>
                <div className="order-btns">
                    
                    <Button variant='danger' onClick={() => this.showAlertDelete(item.id)}>
                        <FontAwesomeIcon className='notification-delete' icon={faTrash} />
                    </Button>
                    
                    
                </div>
            </div>)
        }) : (<div className='notification-row'>لا توجد اشعارات</div>)
        return (
            <div className="page-content">
                <Container fluid>
                    <div className='buffet-wrapper notifications'>
                        <Planets customLoading={this.state.loading} />
                        <div className='header-filter'>
                            <h1 className='title'>الاشعارات</h1>
                            {this.state.notifications.length>0?(
                            <button onClick={()=>this.showAlertDelete('all')} className='btn btn-primary'>مسح الكل</button>
                            ):null}
                        </div>
                        {notifications_list}
                    </div>
                </Container>
                {this.state.alertDelete}
                  {this.state.alertSuccess}
                  {this.realTimeNotification}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        id:state.user.id

    }
}
export default connect(mapStateToProps)(Notifications)