import { faStarHalfAlt,faStar } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import Rating from 'react-rating'
import filledstar from '../../assets/img/star-filled.svg'
import emptystar from '../../assets/img/star.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Review extends Component{
    state={
        rate:'',
        review:'',
        showReasons:false,
        reasons:this.props.reasons,
        selectedReason:''
    }
    handleChange =(e)=>{
        this.setState({
            review:e.target.value
        })
    }
    handleChangeRate=(rate)=>{
        this.setState({rate})
        rate<=2?this.setState({showReasons:true}):this.setState({showReasons:false})
    }
    handleSubmit=(e)=>{
        e.preventDefault()
        this.props.submitReview(this.props.order_id,this.state.review,this.state.rate,this.state.selectedReason)
    }
    addReason=(id)=>{
        this.setState({selectedReason:id})
    }
    render(){
        const reasons = this.state.reasons?this.state.reasons.map(reason=>{
            return(
                <div onClick={()=>this.addReason(reason.id)} className={`reason ${reason.id==this.state.selectedReason? "activeReason":""}`} key={reason.id}>
                    {reason.review}
                </div>
            )
           
        }):null
             return(<div>
                 <Form onSubmit={this.handleSubmit}>
                    <Rating
                        onChange={(rate) => this.handleChangeRate(rate)}
                        initialRating={this.state.rate}
                        emptySymbol={<FontAwesomeIcon className='empty-star' icon={faStar}/>}
                        fullSymbol={<FontAwesomeIcon className='full-star' icon={faStar}/>}
                        className="rating-stars"
                        />
                        {this.state.showReasons?( <Form.Group>
                            <h3>نرجوا اختيار سبب التقييم السئ</h3>
                            <div className="reasons-container">
                                {reasons}
                            </div>
                            
                            
                        </Form.Group>):null}
                       
                        <Form.Group>
                            <label htmlFor="review">إكتب تعليقك</label>
                            <textarea onChange={this.handleChange} id='review'></textarea>
                        </Form.Group>
                        <Form.Group className="text-center">
                        <Button type='submit' variant="primary">تقييم</Button>
                        </Form.Group>
                 </Form>
                 
             </div>)   
        }
    }
                
export default Review