import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import FormData from 'form-data'
class UserForm extends Component{
    state={
        username:this.props.user?this.props.user.username:'',
        fullname:this.props.user?this.props.user.fullname:'',
        email:this.props.user?this.props.user.email:'',
        password:'',
        dep_id:this.props.user?this.props.user.dep_id:'',
        user_type_id:this.props.user?this.props.user.user_type_id:'',
        profile_pic:this.props.user?this.props.user.profile_pic:'',
        credit:this.props.user?this.props.user.credit:'',
    }
    handleChange =(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangeImg =(e)=>{
        this.setState({[e.target.name]:e.target.files[0].name})
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(document.querySelector('#userform'));
        this.props.type=='add' ?this.props.createUser(data):this.props.editUser(data,this.props.id)
    }

    render(){
        const departments= this.props.departments.map(department=>{
            return(
            <option key={department.id} value={department.id} >{department.department}</option>
            )
        })
        const groups= this.props.groups.map(group=>{
            return(
            <option key={group.id} value={group.id}>{group.type}</option>
            )
        })
        return(
            <Form  id="userform" className='text-right' onSubmit={this.handleSubmit}>
                <Form.Group controlId="adduser">
                <label htmlFor="adduser">اسم المستخدم</label>
                    <Form.Control pattern="[أ-يa-zA-Z_\.\-\s]+" required name='username' value={this.state.username} type="text" onChange={this.handleChange} placeholder="اسم المستخدم" />
                </Form.Group>
                <Form.Group controlId="addusername">
                <label htmlFor="addusername">الاسم بالكامل</label>
                    <Form.Control pattern="[أ-يa-zA-Zء\s]+" required name='fullname' value={this.state.fullname} type="text" onChange={this.handleChange} placeholder="الاسم بالكامل" />
                </Form.Group>
                <Form.Group controlId="email">
                <label htmlFor="email">البريد الالكتروني</label>
                    <Form.Control pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required name='email' value={this.state.email} type="email" onChange={this.handleChange} placeholder="البريد الالكتروني" />
                </Form.Group>
                <Form.Group controlId="password">
                <label htmlFor="password">كلمة المرور</label>
                    <Form.Control  name='password' value={this.state.password} type="password" onChange={this.handleChange} placeholder="كلمة المرور" />
                </Form.Group>
                <Form.Group controlId="selectrole">
                <label htmlFor="selectrole">فئة المستخدم</label>
                    <Form.Control name='type_id' defaultValue={this.state.user_type_id}  as="select" onChange={this.handleChange}>
                    <option  value="">إختر</option>
                        {groups}
                    </Form.Control>
                </Form.Group>
                <Form.Group  controlId="selectdepartment" >
                <label htmlFor="selectdepartment">القسم</label>
                    <Form.Control as="select"   name='dep_id' defaultValue={this.state.dep_id} onChange={this.handleChange}>
                    <option >إختر</option>
                    {departments}
                    </Form.Control>
                </Form.Group>
                <Form.Group className='relative'>
                    <input className='form-control ' type="text" disabled value='صورة المستخدم'/>
                    <div className="file btn btn-lg btn-primary col-auto">
                        تحميل
                        <input files={this.state.profile_pic} type="file"  name='profile_pic' onChange={this.handleChangeImg}/>
                    </div>                

                </Form.Group>
                <Form.Group className='form-control flex-sbetween'>
                    <Form.Label>
                        الرصيد
                    </Form.Label>
                    <Form.Check inline label="مفتوح" value="open" checked={this.state.credit === "open"} type="radio" name='credit' id='radioopen' onChange={this.handleChange}/>
                    <Form.Check inline label="اسبوعي" value="weekly" checked={this.state.credit === "weekly"} type="radio" name='credit' id='radiodialy' onChange={this.handleChange} />
                </Form.Group>
                <Form.Group className='text-center'>
                     <Button  variant="primary" type="submit">
                      حفظ
                    </Button>
                </Form.Group>
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default UserForm