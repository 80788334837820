import React, { Component } from 'react';
import './App.scss';
import {  Container ,Row ,Col} from 'react-bootstrap';
import { Route, HashRouter, Switch } from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard'
import NoPermission from './components/dashboard/NoPermission'
import AllDepartments from './components/Departments/AllDepartments';
import AllUsersGroups from './components/UsersGroups/AllUsersGroup';
import AllUsers from './components/Users/AllUsers';
import Favourites from './components/Users/Favourites';
import Profile from './components/Users/Profile';
import SideBar from './components/layout/SideBar';
import SignIn from './components/auth/SignIn';
import ItemsList from './components/Items/ItemsList';
import OrderHistory from './components/Order/OrderHistory';
import HistoryDetails from './components/Order/HistoryDetails';
import TopBar from './components/layout/TopBar'
import serverPath from './components/config'
import {connect} from 'react-redux'
import axios from 'axios'
import SiteSettings from './components/dashboard/SiteSettings';
import CategoriesList from './components/Categories/CategoriesList';
import PresetsList from './components/Presets/PresetsList';
import OrderMenu from './components/Order/OrderMenu';
import Cart from './components/Order/Cart';
import AdminOrderHistory from './components/Order/AdminOrderHistory';
import ReasonsList from './components/Reasons/ReasonsList';
import SearchResult from './components/Order/SearchResult';
import EmployeeItems from './components/Items/EmployeesItems';
import EmployeeOwnItems from './components/Items/EmployeeOwnItems';
import OuterOrderPage from './components/Order/OuterOrderPage';
import Notifications from './components/dashboard/Notifications';

class App extends Component {
  state={
    email:'',
    password:''
  }
 
handleChange=(e)=>{
      this.setState({[e.target.id]:e.target.value})
    }
    
   handleLogout= (e)=>{
      const headers=   {
          'Authorization':'Bearer '+this.props.token
      }
      localStorage.clear();

      axios.post(`${serverPath}/api/logout`, headers,{
          headers: headers
          
        }).then((response) => {
          this.props.Logout('');
          // localStorage.clear();
        });
  }
  render(){
    let content=''
    const token = this.props.token ;
    const permissions=this.props.permissions
   const app= token  ?(<Container fluid>
      <HashRouter> 
          <Row>
            <Col xl={2} className="d-xs-none d-sm-none d-lg-block d-xl-block">
              <SideBar logout={this.handleLogout}/>
            </Col>
            <Col xl={10} md={12} className="nopadding-m">
              <div className='app-content'>
                <TopBar logout={this.handleLogout}/>            
                <Switch>
                  <Route exact path='/' render = {() => (this.props.usertype=='1' ?  (<Dashboard />) : (<OrderMenu/>))} />
                  <Route path='/nopermission' component={NoPermission} />
                  <Route path='/departments' component={AllDepartments} />
                  <Route path='/users' component={AllUsers} />
                  <Route path='/usersgroups' component={AllUsersGroups} />
                  <Route path='/login' component={SignIn} />
                  <Route path='/profile' component={Profile} />
                  <Route path='/sitesettings' component={SiteSettings} />
                  <Route path='/items' component={ItemsList} />
                  <Route path='/categories' component={CategoriesList} />
                  <Route path='/presets' component={PresetsList} />
                  <Route path='/history' component={OrderHistory} />
                  <Route path='/admin-history' component={AdminOrderHistory} />
                  <Route path='/buffet-menu' component={OrderMenu} />
                  <Route path='/cart' component={Cart} />
                  <Route path='/pre-reasons' component={ReasonsList} />
                  <Route path='/favourites' component={Favourites} />
                  <Route path='/historydetails/:id' component={HistoryDetails} />
                  <Route path='/searchresult/:keyword' component={SearchResult} />
                  <Route path='/employeesitems' component={EmployeeItems} />
                  <Route path='/notifications' component={Notifications} />
                  <Route path='/myItems' component={EmployeeOwnItems} />
                  <Route path='/myOrders' component={OuterOrderPage} />
                </Switch>      
              </div>
            </Col> 
          </Row>
          </HashRouter>
          
    </Container>):(<SignIn/>
      )
     
  return (
    <div className="App">
      {app}
      
    </div>
  );
}
}
const mapStateToProps=(state)=>{
  return{
      token:state.auth.token,
      usertype:state.user.usertype,
      permissions:state.user.permissions
  }
} 
const mapDispatchToProps=(dispatch)=>{
  return{
    Logout: (token)=>dispatch({type:'LOGOUT', token:token}),

  }
}
export default connect(mapStateToProps,mapDispatchToProps) (App)
