import React, { Component } from 'react'
import { Button, Modal, Alert, Container, Col, Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import { connect } from 'react-redux'
import itemImg from '../../assets/img/default.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import SearchBar from '../layout/SearchBar'
import Pagination from '../dashboard/Pagination'
import EmployeesItemForm from './EmployeesItemForm'
class EmployeeItems extends Component {
    state = {
        items: [],
        showModal: false,
        typeModal: '',
        selectedId: '',
        selectedItem: '',
        currentPage: 1,
        errmsg: '',
        cerrmsg: '',
        show: '',
        alertDelete: '',
        alertSuccess: '',
        loading: true,
        users: '',
        buffet_items: ''
    }
    async componentDidMount() {
        await this.fillData(this.state.currentPage)
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        const paginationHeight = document.getElementsByClassName("buffet-wrapper")[0].offsetHeight
        wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`

    }
    fillData(page) {
        Promise.all([
            //get users
            axios.get(`${serverPath}/api/User?page=all`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            }),
            //get usertypes
            axios.get(`${serverPath}/api/all_owen_items?page=${page}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            }),
            //get departments
            axios.get(`${serverPath}/api/Item?page=all`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            })
        ])
            .then(([responseOne, responseTwo, responesThree]) => {

                const users = responseOne.data.data;
                const items = responseTwo.data.data;
                const buffet_items = responesThree.data.data;
                this.setState({ buffet_items, loading: false, users, items })

            })
            .catch(errors => {
                this.setState({ errmsg: errors.message })
                if (errors.response.status == 403) {
                    this.setState({ nopermission: true })
                }
            })
    }

    goToPage = (page) => {
        this.fillData(page)
        this.setState({ currentPage: page })
    }
    handleShowModal = (type, item_id, user_id, quantity) => {
        this.setState({ showModal: true, typeModal: type, selectedUserId: user_id ? user_id : '', selectedItemId: item_id, quantity: quantity })
    }
    handleCloseModal = () => {
        this.setState({ showModal: false, typeModal: '', selectedId: '' })
    }
    handleCreate = (item_id, user_id, quantity) => {
        const items = this.state.items;
        let errmsg = this.state.errmsg
        errmsg = '';
        axios.post(`${serverPath}/api/add_owen_item`,
            {
                item_id: item_id,
                quantity: quantity,
                user_id: user_id,
            }
            , {
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                }
            }).then((response) => {
                items.push(response.data.data)
                this.setState({ items })
                this.setState({ showModal: false })
                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({ show: true, errmsg })

                }
                else {
                    this.setState({ errmsg: null })
                }
            })

    }
    handleEdit = (id, quantity, user_id) => {
        const items = this.state.items
        axios.post(`${serverPath}/api/update_owen_item`,
            {
                item_id: id,
                quantity: quantity,
                user_id: user_id
            }
            , {
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                }
            }).then((response) => {
                this.setState({ editModal: false })
                for (let i in items) {

                    if (items[i].item_id == id) {
                        items[i] = '';
                        items[i] = response.data.data
                        break; //Stop this loop, we found it!

                    }
                    this.setState({ items })
                }
                this.setState({ showModal: false })

                this.showAlertSuccess(response.data.message)

            }, (error) => {
                if (error.response) {
                    this.setState({ errmsg: error.response.data.message })
                    this.setState({ show: true })

                }
                else {
                    this.setState({ errmsg: null })
                }
            });
    }
    handleDelete = (id, userId) => {
        const headers = {
            'Authorization': 'Bearer ' + this.props.token,
            'Accept': 'application/json'
        }
        const items = this.state.items.filter(item => {
            return item.item_id !== id
        })
        this.setState({ items, alertDelete: '' })
        axios.post(`${serverPath}/api/remove_owen_item`, {
            item_id: id,
            user_id: userId
        }, { headers: headers }).then((response) => {

            this.showAlertSuccess(response.data.message)

        }, (error) => {
            if (error.response) {
                this.setState({ cerrmsg: error.response.data.message })
                this.setState({ show: true })


            }
            else {
                this.setState({ cerrmsg: null })
            }
        });
    }
    // close deleter alert
    onCancel = () => {
        this.setState({ alertDelete: '' })
    }
    //close success alert
    onCancelSuccess = () => {
        this.setState({ alertSuccess: '' })
    }
    // show alert success
    showAlertSuccess = (msg) => {
        this.setState({
            alertSuccess: <SweetAlert
                success
                confirmBtnText="تم"
                confirmBtnBsStyle="primary"
                title={msg}
                timeout={2000}
                onConfirm={this.onCancelSuccess}
                onCancel={this.onCancelSuccess}
            >
            </SweetAlert>
        })
    }
    // show alert delete
    showAlertDelete = (id, itemname, userId) => {
        this.setState({
            alertDelete: <SweetAlert
                danger
                showCancel
                confirmBtnText="حذف"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="primary"
                cancelBtnText='رجوع'
                title={' هل انت متأكد من مسح ' + (itemname) + ' ؟ '}
                onConfirm={() => this.handleDelete(id, userId)}
                onCancel={this.onCancel}
            >
            </SweetAlert>
        })
    }
    setShow = () => {
        const show = false
        this.setState({ show })
    }
    render() {
        const permissions = this.props.permissions;

        const items = this.state.items.length > 0 ? this.state.items.map((item, index) => {
            return (
                item.user ? (
                    <div key={index} className='list-item'>
                        <div className="user_info">
                            <img src={item.user.profile_pic ? item.user.profile_pic : itemImg} alt={item.user.name} title={item.user.name} />
                            <h4>{item.user.fullname}</h4>
                        </div>
                        <h4>{item.item.name}</h4>
                        <div className="record-controls">
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'edit-item'
                                        ? (
                                            <Button key={Math.random()} variant="success" onClick={() => this.handleShowModal("edit", item.item.id, item.user.id, item.quantity)}><FontAwesomeIcon icon={faPen} /></Button>
                                        )
                                        : (null))
                                }))
                                : null}
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'delete-item'
                                        ? (
                                            <Button key={Math.random()} variant="danger" onClick={() => this.showAlertDelete(item.item_id, item.item.name, item.user_id)}><FontAwesomeIcon icon={faTrash} /></Button>

                                        )
                                        : (null))
                                }))
                                : null}
                        </div>

                    </div>
                ) : (null)


            )
        }) : (<Col>لا توجد بيانات</Col>)

        return (
            <div className='page-content'>
                <div className='page-header'>
                    <Container fluid>
                        <Row>
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-item'
                                        ? (
                                            <Col key={Math.random()} xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={() => this.handleShowModal("add")}>أضف منتج جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <div className='buffet-wrapper employeeItems'>
                        <Planets customLoading={this.state.loading} />
                        {items}
                    </div>

                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.typeModal == 'add' ? 'إضافة منتج جديد' : 'تعديل منتج'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeesItemForm
                                type={this.state.typeModal}
                                createItem={this.handleCreate}
                                editItem={this.handleEdit}
                                buffet_items={this.state.buffet_items}
                                users={this.state.users}
                                item_id={this.state.selectedItemId}
                                user_id={this.state.selectedUserId}
                                quantity={this.state.quantity}
                                errmsg={this.state.errmsg}
                                show={this.state.show}
                                setShow={this.setShow}
                            />
                        </Modal.Body>

                    </Modal>
                    {this.state.cerrmsg ? (

                        <Alert show={this.state.show} variant="danger" dismissible onClose={this.setShow}>

                            <span className='cursor' onClick={this.handleLogout}>{this.state.cerrmsg}</span>
                        </Alert>) : null}
                </Container>
                {this.state.alertDelete}
                {this.state.alertSuccess}
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        usertype: state.user.usertype,
        permissions: state.user.permissions

    }
}

export default connect(mapStateToProps)(EmployeeItems)