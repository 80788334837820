import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';


class SpecialOrder extends Component{
        state={
           
            special:'',
            
   }

     handleSubmit = (e) => {
         e.preventDefault()
        this.props.create(this.state.special)        
    }

  
    handleChangeText=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    render(){
       
        return(
            <Form  id="userform" className='text-center AddToCart' onSubmit={this.handleSubmit} >
               
                <Form.Group>
                    <label htmlFor='notes' className='text-right'>إكتب تفاصيل طلبك</label>
                    <textarea  required id='notes' name='special' rows='5' placeholder='اكتب طلبك هنا' onChange={this.handleChangeText}></textarea>
                </Form.Group>
                <Button  variant="primary" type="submit">
                    إرسل طلبك
                </Button>

                {this.props.errmsg &&this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )}
    }
                
export default SpecialOrder