import React, { Component } from 'react'
import { Button , Modal , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import PresetsForm from './PresetForm'
import SearchBar from '../layout/SearchBar'
import Pagination from '../dashboard/Pagination'
import PresetListItem from '../imports/PresetListItem'
class PresetsList extends Component{
    constructor(props){
        super(props);
        this.state={
            presets:[],
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            currentPage:1,
            errmsg:'',
            show:'',
            alertDelete:'',
            alertSuccess:'',
            loading:true,
            items:'',
            categories:'',
            presetItems:[],
            request:''
        }
    }
   async componentDidMount(){
    await this.fillData(this.state.currentPage)
    // const wrapper=document.querySelector(".buffet-wrapper")
    // const offset = wrapper.offsetTop
    // const paginationHeight=document.querySelector(".pagination").style.height
    // wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`
}

fillData =(page)=>{
    Promise.all([
        axios.get(`${serverPath}/api/Presit?page=${page}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }),
        axios.get(`${serverPath}/api/Item?page=all`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }),
        axios.get(`${serverPath}/api/Category`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
    ])
    .then(([responseOne,responseTwo,responesThree])=>{
        
        const presets= responseOne.data.data.data;
        const request= responseOne.data.data;
        const items= responseTwo.data.data;
        const categories= responesThree.data.data;
        this.setState({categories,loading:false,request,presets,items})  

    })
    .catch(errors => {
        this.setState({errmsg:errors.message})
        if(errors.response.status==403){
            this.setState({nopermission:true})
        }
    })
}
goToPage =(page)=>{
    this.fillData(page)
    this.setState({currentPage:page})
}
    handleShowModal=(type,id,item,presetItems)=>{

        this.setState({errmsg:'',showModal:true,typeModal:type,selectedId:id?id:'',selectedItem:item?item:'',presetItems})

    }
    handleCloseModal=()=>{
        this.setState({showModal:false,typeModal:'',selectedId:''})
    }
    handleCreate = (data)=>{
        const presets = this.state.presets;
        let errmsg=this.state.errmsg
        errmsg='';
        axios.post(`${serverPath}/api/Presit`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            if(this.state.request.last_page <= 1){

                presets.push(response.data.data)
            }
            this.setState({showModal:false,presets})
            this.showAlertSuccess()
        }, (error) => {
            if (error.response.status === 403) {
                this
                    .props
                    .history
                    .push('/nopermission')
            }
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
            }
            else{
            this.setState({errmsg : null})
            }
        })
        
    }
    handleEdit = (data,id)=>{
        const presets= this.state.presets
        let errmsg=this.state.errmsg
        axios.post(`${serverPath}/api/update/presit/${id}`,
            data
        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }).then((response)=>{
            this.setState({editModal:false})
            for (let i in presets) {
                
                if (presets[i].id == id) {
                    presets[i]='';
                    presets[i]=response.data.data
                break; //Stop this loop, we found it!

                }
                this.setState({presets})
            } 
            this.setState({showModal:false})
            this.showAlertSuccess()

        }, (error) => {
            if (error.response.status === 403) {
                this
                    .props
                    .history
                    .push('/nopermission')
            }
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
            this.setState({errmsg : null})
            }
        });
    }
    handleDelete=(id)=>{
        const headers=   {
            'Authorization':'Bearer '+this.props.token,
            'Accept':'application/json'
        }
       let errmsg = this.state.errmsg
        axios.delete(`${serverPath}/api/Presit/${id}`, {headers: headers}).then((response)=>{

            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})
    
            }
            else{
              this.setState({errmsg : null})
            }
          });
        const presets = this.state.presets.filter(preset=>{
            return preset.id !== id
        })
        this.setState({presets,alertDelete:''})
    }  
    onCancel = ()=>{
        this.setState({alertDelete:''})
    }
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    showAlertSuccess=()=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title="تم تنفيذ طلبك بنجاح !"
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
    setShow = () => {
        const show = false
        this.setState({show})
    }
    showAlertDelete=(id,presetname)=>{
        this.setState({
            alertDelete:<SweetAlert
            danger
            showCancel
            confirmBtnText="حذف"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            cancelBtnText='رجوع'
            title= {  ' هل انت متأكد من مسح ' + (presetname) + ' ؟ '}
            onConfirm={()=>this.handleDelete(id)}
            onCancel={this.onCancel}
          >
          </SweetAlert>
        })
       
    }
    
    render(){
        if(this.state.loading == false){
            const wrapper=document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight=this.state.request.last_page >1?'87px':'32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`
            
        }
        const permissions=this.props.permissions;

        const presets=this.state.presets.length>0 ? this.state.presets.map((preset,index)=>{
            let presetPoints = 0
            const points=preset.items.length>0?preset.items.map(item=>{
                presetPoints+=  Number(item.pivot.points)
                return presetPoints 
            }):null 
            return(
                <PresetListItem usertype={this.props.usertype} type="preset" key={index} preset={preset} permissions={permissions} showAlertDelete={this.showAlertDelete} handleShowModal={this.handleShowModal} preset_points={presetPoints}/>
            )
        }):(<Col>لا توجد بيانات</Col>)
  
        return(
            <div className='page-content'>
                 <div className='page-header'>
                    <Container fluid>
                        <Row>
                        {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-presit'
                                        ? (
                                            <Col xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal("add")}>أضف مشروب جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>                   
                </div>
                <Container fluid>
                    <div className='buffet-wrapper presets'>
                    <Planets customLoading={this.state.loading} />

                        <Row className='stretch'>
                            {presets}
                        </Row>
                       
                    </div> 
                    {this.state.request.last_page >1?
                            <Pagination  goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page}/>  :null
                        }
                    <Modal className='presetModal' animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>
                    {this.state.typeModal == 'add' ? 'إضافة مشروب جديد':'تعديل مشروب'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PresetsForm 
                        type={this.state.typeModal}
                        preset={this.state.selectedItem} 
                        createPreset={this.handleCreate} 
                        editPreset={this.handleEdit} 
                        items={this.state.items}
                        cats={this.state.categories}
                        presetItems={this.state.presetItems}
                        show={this.state.show}
                        setShow={this.setShow}
                        errmsg={this.state.errmsg}
                        />
                    </Modal.Body>
              
                </Modal>
                </Container>
                {this.state.alertDelete}
                  {this.state.alertSuccess}
            </div>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        permissions:state.user.permissions,
        usertype:state.user.usertype

    }
}

export default connect(mapStateToProps) (PresetsList)
