import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();


class HistoryDetailsCard extends Component{
        state={
            itemContent:this.props.selectedOrder,
            cart_items:[]

   }
   componentDidMount(){
    
    const cart_items = this.state.cart_items;
    const itemContent = this.state.itemContent;
    if(this.props.type=='normal'){
    JSON.parse(itemContent.presit_details).cart.cart_items.map(item=>{
        cart_items.push(item)
    })
    this.setState({cart_items})
}
}
    
    render(){
    
        const cart_items = this.state.cart_items;
        const items= cart_items && this.props.type=='normal'? cart_items.map((item,index)=>{
            return(
                    <div key={item.item_id} className='order-item-info flex-sbetween'>
                        <span >
                        {item.item.name}  ( {item.item.label} )     
                        </span>
                        <span className="item-points">
                        {item.points}   
                        </span>
                          
                    </div>
            )
        }):this.state.itemContent

        return(
            <Form  id="userform" className='AddToCart' onSubmit={this.handleSubmit} >
                
                <h3 className='form-toptitle border-bottom mt-0 text-center'>{this.props.type=='normal'?this.props.selectedOrder.order_preset.name:"طلب خاص"}</h3>
                <h3 className='form-toptitle'>المحتويات</h3>

                {items}
                <hr/>
                {this.props.type=='normal'?(<div className='total-points flex-sbetween'>
                    <span>إجمالي النقاط</span>                   
                    <span>{this.state.itemContent.points}</span>
               </div>):null}
               
            </Form>
        )}
    }
                
export default HistoryDetailsCard