import React, { Component } from 'react'
import { Button , Alert , Container,Dropdown,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import Review from './Review'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes ,faSearch,faAngleDown} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import Rating from 'react-rating'
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import filledstar from '../../assets/img/star-filled.svg'
import emptystar from '../../assets/img/star.svg'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../dashboard/Pagination'
import  { Redirect } from 'react-router-dom'
class AdminOrderHistory extends Component{
        state={
            historyList:[],
            filteredList:[],
            filter:false,
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            errmsg:'',
            cerrmsg:'',
            show:'',
            alert:'',
            loading:true,
            selectedProduct:'',
            selectedDep:'',
            selectedRate:'',
            selectedStatus:'',
            selectedDateTo:'',
            selectedDateFrom:'',
            keyword:'',
            departments:'',
            currentPage:1,
            nopermission:false,
            request:'',
            filtered:false
            
        }
    
   async componentDidMount(){
       
    this.fillData(this.state.currentPage);
    // const wrapper=document.querySelector(".buffet-wrapper")
    // const offset = wrapper.offsetTop
    // const paginationHeight=document.querySelector(".pagination").style.height
    // wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`

}
fillData =(page)=>{
    Promise.all([
        axios.get(`${serverPath}/api/all_orders?page=${page}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }), 
        axios.get(`${serverPath}/api/Department`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        }),
        
    ])
    .then(([responseOne,responseTwo])=>{
        const historyList= responseOne.data.order.data;
        const departments =responseTwo.data.data;
        const request= responseOne.data.order;

        this.setState({historyList,loading:false,departments,request})

    })
    .catch(errors => {
        this.setState({errmsg:errors.message,show:true})
        if(errors.response.status==403){
            this.setState({nopermission:true})
        }
    })
}
goToPage =(page)=>{
    this.setState({loading:true,currentPage:page})
    if(this.state.filtered){
        this.fillDataFilter(page)
    }
    else{
        this.fillData(page)

    }
    
}

   
cancelOrder=(id)=>{
    let cerrmsg=this.state.cerrmsg
     axios.post(`${serverPath}/api/cancel_order`, {order_id:id,user_id:this.props.id},{
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const historyList =this.state.historyList.filter(item=>
            item.id==id?item.status='Cancelled':item.status
          )
       this.setState({historyList,loading:false})
    },(error)=>{
        if (error.response) {
            error.response.data.errors.map(err => (cerrmsg += err + " . "))
            this.setState({show: true,cerrmsg,loading:false})

        }
       
        else{
          this.setState({cerrmsg : null})
        }
      })
}

handleCloseModal=()=>{
    this.setState({showModal:false})
}
setShow=()=>{
    this.setState({show:false})
}

onCancel = ()=>{
    this.setState({alert:''})
}

showAlert=(type,id,msg)=>{

    this.setState({
        alert:<SweetAlert
        success
        confirmBtnText="حسناً"
        confirmBtnBsStyle="success"
        title={msg}
        onConfirm={this.onCancel}
        onCancel={this.onCancel}
      >
    </SweetAlert>
    })
} 
onChangeDate = (dates)=>{
    let [start, end] = dates;
    const selectedDateFrom=Moment(start).format("YYYY-M-D");
    const selectedDateTo=end?Moment(end).format("YYYY-M-D"):end;
    this.setState({start,end})
    this.setState({selectedDateFrom,selectedDateTo})
}
handleChangeText=(e)=>{
    this.setState({keyword:e.target.value})
}
doFilter=(e,page)=>{
    e.preventDefault()
    this.setState({loading:true})
    this.fillDataFilter(page)
}
fillDataFilter =(page)=>{
    let cerrmsg = this.state.cerrmsg
    cerrmsg = ''
    if(!this.state.keyword&&!this.state.selectedStatus&&!this.state.selectedDep&&!this.state.selectedDateFrom&&!this.state.selectedDateTo&&!this.state.selectedRate){
        this.setState({currentPage:1})
    }
    axios.get(`${serverPath}/api/filter_orders?page=${page}&keyword=${this.state.keyword}&status=${this.state.selectedStatus}&dep_id=${this.state.selectedDep}&sumission_date_from=${this.state.selectedDateFrom}&sumission_date_to=${this.state.selectedDateTo}&rate=${this.state.selectedRate}`, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const historyList= response.data.order.data?response.data.order.data:[];
        const request= response.data.order;
        const msg = response.data.message
       this.setState({historyList,loading:false,cerrmsg:msg,request,filtered:true})
    },(error)=>{

        if (error.response) {
            error.response.data.errors.map(err => (cerrmsg += err + " . "))
            this.setState({show: true,cerrmsg,historyList:null,loading:false})

        }
        else{
          this.setState({cerrmsg : null})
        }
      })
}
setDepartment=(id,name)=>{
    this.setState({selectedDep:id,selectedDepText:name})
}
setStatus=(status,name)=>{
    this.setState({selectedStatus:status,selectedStatusText:name})
}
setRate=(rate,name)=>{
    this.setState({selectedRate:rate,selectedRateText:name})
}
    render(){
        if(this.state.loading == false){
            const wrapper=document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight=this.state.request.last_page >1?'87px':'32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`
            
        }
        const departments=this.state.departments?this.state.departments.map((department,index)=>{
                return(
                    <Dropdown.Item key={department.id} onClick={()=>this.setDepartment(department.id,department.department)}>
                        {department.department}                                        
                    </Dropdown.Item>)  }
        ):(<Dropdown.Item>لا توجد اقسام</Dropdown.Item>);
        const historyList = this.state.historyList.length>0?this.state.historyList.map(item=>{
            return(
                <div className='order-row' key={item.id}>
                   <div className='order-info'>
                       <h4> 
                       <NavLink to={'/historydetails/'+item.id}>
                           طلب رقم #{item.id} 

                        </NavLink> 
                           <span className={`order-status `+item.status}>
                               {item.status =='Pending'? 'قيد الانتظار' : item.status=='Completed'?'تم الاستلام':item.status=='Cancelled'?"تم الالغاء":"قيد التنفيذ"}</span>
                        </h4>
                        <span className='order-date'>{Moment(item.created_at).format("MMMM D YYYY , hh:mm A")}</span>

                   </div>
                    <div className='order-btns'>
                        {item.status =='Pending'?(
                            <Button variant='primary'  onClick={()=>this.cancelOrder(item.id)}>
                                <FontAwesomeIcon  className='order-icon' icon={faTimes}/> 
                                إلغاء الطلب
                            </Button>
                        ):(null)}
                        {item.status =='Completed'?(
                            <>
                            {item.rating? (
                                <Rating
                                className='rating'
                                readonly
                                initialRating={item.rating.rate}
                                emptySymbol={<img src={emptystar}className="icon" />}
                                fullSymbol={<img src={filledstar} className="icon" />}
                                />
                            )
                            :(
                                    <div className='order-info'>
                                    <h4> لا يوجد تقييم حتى الان
                                    </h4>
                                    </div>
                                )
                            
                        }

                            </>
                        ):(null)}
                        
                    </div>
                </div>
            )
        }):(
            <div className='order-row'>
                <div className='order-info'>
                    <h4> 
                        عفوا لا توجد بيانات
                    </h4>
                </div>
            </div>
        )
               return(
            this.state.nopermission==true?( <Redirect to='/nopermission'  />):
            (
            <div className='page-content'>

                <Container fluid>
                    <div className='buffet-wrapper order-history adminHistory'>
                    <Planets customLoading={this.state.loading} />
                    <h1 className='title'>طلبات البوفيه</h1>   

                    <div className='header-filter'>
                        <div className='relative keyword-form'>
                        
                        <form className='search-form' onSubmit={this.doFilter}>
                            <input name="keyword" className='search-input' onChange={this.handleChangeText} type='text' placeholder='إكتب كلمة البحث'/>
                            <button className='search-icon-btn' onClick={this.doFilter} >
                                <FontAwesomeIcon className='search-icon' icon={faSearch}/>
                            </button>
                        </form>
                        </div>
                                     
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic1">
                                <span>
                                    {this.state.selectedDepText?this.state.selectedDepText:"القسم"}
                                </span>
                                <FontAwesomeIcon className='filter-arrow' icon={faAngleDown}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>this.setDepartment("","")}>
                                    الكل                                        
                                </Dropdown.Item>
                               {departments}
                            </Dropdown.Menu>
                        </Dropdown>                     
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic2">
                                <span>
                                {this.state.selectedStatusText?this.state.selectedStatusText:"حالة الطلب"}
                                </span>
                                <FontAwesomeIcon className='filter-arrow' icon={faAngleDown}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>this.setStatus("","")}>
                                    الكل                                        
                                </Dropdown.Item>
                            <Dropdown.Item onClick={()=>this.setStatus("InProgress","قيد التنفيذ ")}>
                                    قيد التنفيذ                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setStatus("Pending","قيد الانتظار ")}>
                                    قيد الانتظار                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setStatus("Cancelled","تم الالغاء ")}>
                                    تم الالغاء                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setStatus("Completed","تم التسليم ")}>
                                    تم التسليم                                        
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>                     
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic3">
                                <span>
                                {this.state.selectedRateText?this.state.selectedRateText:"التقييم"}
                                </span>
                                <FontAwesomeIcon className='filter-arrow' icon={faAngleDown}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>this.setRate("","")}>
                                    الكل                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setRate("1","نجمة ")}>
                                    نجمة                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setRate("2","نجمتين ")}>
                                    نجمتين                                       
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setRate("3","3 نجوم ")}>
                                    3 نجوم                                        
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setRate("4","4 نجوم ")}>
                                    4 نجوم                                       
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>this.setRate("5","5 نجوم ")}>
                                    5 نجوم                                       
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>     
                                     
                        <Dropdown className='datepicker-dropdown'>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic4">
                                تاريخ الطلب
                                <FontAwesomeIcon className='filter-arrow' icon={faAngleDown}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <DatePicker
                                selected={this.state.start}
                                onChange={this.onChangeDate}
                                startDate={this.state.start}
                                endDate={this.state.end}
                                selectsRange
                                inline
                                dateFormat="MMMM d, yyyy"
                                />   
                            </Dropdown.Menu>
                        </Dropdown>   
                        <Button onClick={this.doFilter} variant="primary">إبحث الان</Button>                  
                        </div>
                        {historyList}
                    </div>
                    {this.state.request.last_page >1?
                         <Pagination  goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page}/>  :null
                     }
                    {this.state.alert}
                    {this.state.errmsg && this.state.show?(
                  
                  <Alert  variant="danger" dismissible onClose={this.setShow}>
                   
                    {this.state.errmsg}
                  </Alert>) : null}   
                    {this.state.cerrmsg && this.state.show?(
                  
                  <Alert  variant="danger" dismissible onClose={this.setShow}>
                   
                    {this.state.cerrmsg}
                  </Alert>) : null}   
                    </Container>
                </div>
        ))
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        id:state.user.id

    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      CartItems: (cartItems)=>dispatch({type:'CARTITEMS', cartItems:cartItems}),  

    }
  }

export default connect(mapStateToProps,mapDispatchToProps) (AdminOrderHistory)