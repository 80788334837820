import React, { Component } from 'react';
import axios from 'axios';
import serverPath from '../config';
import Modal from 'react-bootstrap/Modal'
import { Button, Alert, Col, Row, Container } from 'react-bootstrap';
import DepartmentForm from './DepartmentForm'
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { Planets } from 'react-preloaders';
import SearchBar from '../layout/SearchBar'

class AllDepartments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departments: '', //all departments
            department: '', //when update store department here
            departmentId: '', // use on edit and delete
            errmsg: '', // error msg send it to other components
            cerrmsg: '', // error msg used here
            show: true,
            alertDelete: '',
            alertSuccess: '',
            perPage: '', // for pagination
            loading: true, //for preloader
            typeModal: '', //used to determine add or edit department
            showModal: false
        }
        this._isMounted = false
    }

    async componentDidMount() {
        const wrapper=document.querySelector(".buffet-wrapper")
        let cerrmsg=this.state.cerrmsg
        const offset = wrapper.offsetTop
        wrapper.style.height = 'calc(100vh - ' + offset + 'px - 40px)'
        this._isMounted = true
        if (this._isMounted) {
            await axios
                .get(`${serverPath}/api/Department`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.props.token
                    }
                })
                .then((response) => {
                    //recieve data from response to store it at state
                    const departments = response.data.data;
                    //perpage this for pagination you can check pagination component and usage of it below
                    const perPage = response.data.data.per_page
                    this.setState({ departments, loading: false })
                    this.setState({ perPage })
                }, (error) => {
                    if (error.response.status === 403) {
                        this
                            .props
                            .history
                            .push('/nopermission')

                    }
                    if (error.response) {
                        //all errors have the same code which it solve multi errors at the same form . we have issue here , backend may reply with multi erros at the same request so I tried to handle it and map it at one state
                        error.response.data.errors.map(err => (cerrmsg += err + " . "))
                        this.setState({loading: false,cerrmsg})
        
                    } else {
                        this.setState({ cerrmsg: null })
                    }
                })
        }
    }
    // nopermission(){     history.push("/nopermission/"); }
    //show alert for delete true or false 
    showAlertDelete = (id) => {
        this.setState({
            alertDelete: <SweetAlert
                danger
                showCancel
                confirmBtnText="حذف"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="primary"
                cancelBtnText='رجوع'
                title="هل انت متأكد من مسح القسم ؟"
                onConfirm={() => this.handleDelete(id)}
                onCancel={this.onCancel}>
                </SweetAlert>
        })
    }
    //here we may unify alert dismiss function and put alertSuccess and alertDelete at the same function 
    onCancel = () => {
        this.setState({ alertDelete: '' })
    }
    onCancelSuccess = () => {
        this.setState({ alertSuccess: '' })
    }
    //delete department depend on it's id
    handleDelete = (id) => {
        const headers = {
            'Authorization': 'Bearer ' + this.props.token
        }
        let errmsg=this.state.errmsg
        //this to remove from current list at front end (realtime)
        const departments = this
            .state
            .departments
            .filter(department => {
                return department.id !== id
            })
        this.setState({ departments })
        axios
            .delete(`${serverPath}/api/Department/${id}`, { headers: headers })
            .then((response) => {
                this.showAlertSuccess()
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                } else {
                    this.setState({ errmsg: null })
                }
            })
        this.setState({ alertDelete: '' })
    }

    // function to show create (add) department modal
    // function to show add or edit department modal  depend on typeModal state
    handleShowModal = (type, department, id) => {
        const showModal = true;
        this.setState({ departmentId: id, department: department, showModal, typeModal: type })
    };
    showAlertSuccess = () => {
        this.setState({
            alertSuccess: <SweetAlert
                success
                confirmBtnText="تم"
                confirmBtnBsStyle="primary"
                title="تم تنفيذ طلبك بنجاح !"
                timeout={2000}
                onConfirm={this.onCancelSuccess}
                onCancel={this.onCancelSuccess}></SweetAlert>
        })
    }
    handleCreate = (department) => {
        const departments = this.state.departments;
        let errmsg = this.state.errmsg
        errmsg = '';

        axios.post(`${serverPath}/api/Department`, {
            department: department
        }, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Accept': 'application/json'
            }
        }).then((response) => {
            departments.push(response.data.data)
            this.setState({ departments })
            this.setState({ showModal: false })
            this.showAlertSuccess()
        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})

            } else {
                this.setState({ errmsg: null })
            }
        })

    }
    handleEdit = (department, department_id) => {
        let departments = this.state.departments;
        let errmsg = this.state.errmsg;
        errmsg=''
        axios
            .put(`${serverPath}/api/Department/${department_id}?department=${department}`, department, {
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                this.setState({ showModal: false })
                for (let i in departments) {

                    if (departments[i].id == department_id) {
                        departments[i].department = department

                        break; //Stop this loop, we found it!

                    }
                    this.setState({ departments })
                }
                this.showAlertSuccess()
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                }else {
                    this.setState({ errmsg: null })
                }
            });
    }
    handleCloseModal = () => {
        this.setState({ showModal: false, errmsg: '' })
    }
    setShow = () => {
        const show = false
        this.setState({ show })
    }

    render() {
        const departments = this.state.departments;
        const permissions = this.props.permissions;
        const allDepartments = departments.length
            ? (departments.map((department, index) => {
                    return (
                        <li key={department.id} className='collection-item'>
                            <span>{department.department}</span>
                            <div className='record-control'>
                                {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'edit-department'
                                            ? (
                                                <Button key={Math.random()}
                                                    variant="success"
                                                    onClick={() => this.handleShowModal('edit', department.department, department.id)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </Button>
                                            )
                                            : (null))
                                    }))
                                    : null}
                                {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'delete-department'
                                            ? (
                                                <Button key={Math.random()} variant="danger" onClick={() => this.showAlertDelete(department.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            )
                                            : (null))
                                    }))
                                    : null}

                            </div>
                        </li>
                    )
            }))
            : <p className='collection-item'>لا توجد بيانات
            </p>;

        return (

            <div className='page-content'>
                <div className='page-header'>
                    <Container fluid>
                        <Row>
                            {/* checking permission for add  */}
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-department'
                                        ? (
                                            <Col key={index} xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal("add")}>أضف قسم جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}

                            <Col>
                                <SearchBar />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <ul className="collection buffet-wrapper">
                        <Planets customLoading={this.state.loading} /> {allDepartments}
                    </ul>
                    {this.state.cerrmsg
                        ? (

                            <Alert
                                show={this.state.show}
                                variant="danger"
                                dismissible
                                onClose={this.setShow}>

                                <span>{this.state.cerrmsg}</span>
                            </Alert>
                        )
                        : null}
                </Container>
                <Modal
                    animation={false}
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.typeModal == 'add'
                                ? 'إضافة قسم جديد'
                                : 'تعديل قسم'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DepartmentForm
                            ctype={this.state.typeModal}
                            setShow={this.setShow}
                            department={this.state.department}
                            show={this.state.show}
                            errmsg={this.state.errmsg}
                            editDepartment={this.handleEdit}
                            id={this.state.departmentId}
                            createDepartment={this.handleCreate}
                            token={this.props.token} />
                    </Modal.Body>

                </Modal>

                {this.state.alertDelete}
                {this.state.alertSuccess}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, permissions: state.user.permissions, usertype: state.user.usertype }
}

export default connect(mapStateToProps)(AllDepartments)
