import React from 'react'
import { Button ,Col } from 'react-bootstrap';
import itemImg from '../../assets/img/default.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart ,faShoppingCart,faTrash ,faPen} from '@fortawesome/free-solid-svg-icons'

function OrderListItem(props){
    //this component for preset item list change view 
    const preset=props.preset;
    return(
        props.type=='menu'?(
        <Col  lg={3} md={6} xs={12} key={preset.id}>
            <div className='item-card'>
                <div className='item-quantity'>
                    <img src={preset.image ? preset.image : itemImg} alt={preset.name} title={preset.name}/>
                </div>  
                <div className='preset-info'>
                    <h4>{preset.name}</h4>
                    <h5>
                        {`النقاط : ${props.preset_points} نقاط`}
                    </h5>
                    {preset.items.length>0?preset.items.map(item=>{
                            return (<span className='preset-item' key={Math.random()}>{item.pivot.quantity} {item.name} </span>)
                        }):null} 
                </div>                         
                <div className='record-control'>
                    
                    <Button  variant="primary" onClick={()=>props.handleShowModal("add",preset.id,preset,preset.items,props.preset_points)}><FontAwesomeIcon  icon={faShoppingCart}/></Button>
                    {props.fav.includes(preset.id)?(
                    <Button  variant="danger " className='btn-fav isfav'  onClick={()=>props.handleAddToFav(preset.id)}><FontAwesomeIcon  icon={faHeart}/></Button>
                    ):(<Button  variant="danger" className='btn-fav'  onClick={()=>props.handleAddToFav(preset.id)}><FontAwesomeIcon  icon={faHeart}/></Button>)}
                </div>
            </div>                  
        </Col>
        ):(
        <Col  lg={4} md={6} xs={12} key={preset.id}>
            <div className='item-card'>
                <div className='item-data'>
                <div className='preset-img'>
                    <img src={preset.image ? preset.image : itemImg} alt={preset.name} title={preset.name}/>
                </div>  
                <div className='preset-info'>
                    <h4>{preset.name}</h4>
                    <h5>
                        {`النقاط : ${props.preset_points} نقاط`}
                    </h5>
                    {preset.items.length>0?preset.items.map(item=>{
                            return (<span className='preset-item' key={Math.random()}>{item.pivot.quantity} {item.name} </span>)
                        }):null} 
                </div>                         
                </div>
                <div className='record-control'>
                {props.permissions
                            ? (props.permissions.map((permission, index) => {
                                return (permission === 'edit-presit'
                                    ? (
                                        <Button  variant="success" onClick={()=>props.handleShowModal("edit",preset.id,preset,preset.items)}><FontAwesomeIcon  icon={faPen}/></Button>

                                    )
                                    : (null))
                            }))
                            : null}
                {props.permissions
                            ? (props.permissions.map((permission, index) => {
                                return (permission === 'delete-presit'
                                    ? (
                                        <Button  variant="danger" onClick={()=>props.showAlertDelete(preset.id,preset.name)}><FontAwesomeIcon icon={faTrash}/></Button>

                                    )
                                    : (null))
                            }))
                            : null}

                </div>
            </div>                  
        </Col>
        )
        
    )
}
export default OrderListItem