import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import { faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function ConsumedPresets(props) {
    const [order,setOrder]=useState(true)
    const check= props.data && props.data.length>0;//return true or false
    //getting top consumed presets

    const getTopConsumed =  check?props.data.map((item,index)=>{
        return(
            <li className='order-item' key={index}>
                <span className='order-number'>{index+1}</span>
                <span>{item.name}</span>
            </li>
        )
    }):(<li className='order-item'>لا توجد بيانات حتى الان </li>)
    return (
        <div className='statistics-panel panel-light'>
            <div className="panel-heading">
                <h3>المشروبات المطلوبة</h3>
                {check?(<button className='btn'  onClick={()=>setOrder(!order)}>
                    الترتيب 
                    <div className="panel-icon">
                        <FontAwesomeIcon className={order?'active':''} icon={faCaretUp}/>
                        <FontAwesomeIcon className={order?'':'active'} icon={faCaretDown}/>
                    </div>
                    
                </button>):(null)}
            </div>
            <ul>
                {order?getTopConsumed:getTopConsumed.reverse()}
            </ul>
            
           
            
        </div>
    )
}

