import React, { Component } from 'react'
import { Button, Modal, Container, Col, Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import AddToCart from './AddToCart'
import SpecialOrder from './SpecialOrder';
import SearchBar from '../layout/SearchBar'
import Pagination from '../dashboard/Pagination'
import { Redirect } from 'react-router-dom'
import PresetListItem from '../imports/PresetListItem'
class OrderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presets: [],
            showModal: false,
            typeModal: '',
            selectedId: '',
            selectedItem: '',
            errmsg: '',
            show: '',
            alertDelete: '',
            alertSuccess: '',
            loading: true,
            items: '',
            categories: '',
            presetItems: [],
            selectedPoints: '',
            fav: [],
            cartItems: [],
            currentPage: 1,
            request: '',
            nopermission: false
        }
    }
    async componentDidMount() {
        // calling fetching function which fill data onto component
        await this.fillData(this.state.currentPage)
        // get wrapper height
        // const wrapper=document.querySelector(".buffet-wrapper")
        // const offset = wrapper.offsetTop
        // const paginationHeight=document.querySelector(".pagination").style.height
        // wrapper.style.height = `calc(100vh -  ( ${offset} px + ${paginationHeight} px))`
        //set favourites
        this.setState({ fav: this.props.fav })
    }
    //filling function have all prerequests for mounting component
    fillData = (page) => {
        Promise.all([
            axios.get(`${serverPath}/api/Presit?page=${page}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            }),
            axios.get(`${serverPath}/api/Item`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            }),
            axios.get(`${serverPath}/api/Category`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                }
            })
        ])
            .then(([responseOne, responseTwo, responesThree]) => {

                const presets = responseOne.data.data.data;
                const request = responseOne.data.data;
                const items = responseTwo.data.data.data;
                const categories = responesThree.data.data;
                this.setState({ categories, loading: false, request, presets, items })

            })
            .catch(errors => {
                this.setState({ errmsg: errors.message })
                if (errors.response.status == 403) {
                    this.setState({ nopermission: true })
                }
            })
    }
    // pagination
    goToPage = (page) => {
        this.setState({ loading: true, currentPage: page })
        this.fillData(page)

    }
    //show edit / create modal
    handleShowModal = (type, id, item, presetItems, points) => {

        this.setState({ selectedPoints: points, showModal: true, typeModal: type, selectedId: id ? id : '', selectedItem: item ? item : '', presetItems })
    }
    //closing modal
    handleCloseModal = () => {
        this.setState({ showModal: false, typeModal: '', selectedId: '', errmsg: '' })
    }
    //close succes alert
    onCancelSuccess = () => {
        this.setState({ alertSuccess: '' })
    }
    //setting up access sweetalert
    showAlertSuccess = (msg) => {
        this.setState({
            alertSuccess: <SweetAlert
                success
                confirmBtnText="تم"
                confirmBtnBsStyle="primary"
                title={msg}
                timeout={2000}
                onConfirm={this.onCancelSuccess}
                onCancel={this.onCancelSuccess}
            >
            </SweetAlert>
        })
    }
    //add to cart function
    handleCreate = (presetId, items, quantity, quantity2, privatec, notes) => {
        let errmsg = this.state.errmsg
        errmsg = '';
        axios.post(`${serverPath}/api/add_to_cart`,
            {
                presit_id: presetId,
                items: items,
                quantity: quantity,
                quantity2: quantity2,
                mine: privatec,
                notes: notes
            }

            , {
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                    'Accept': 'application/json',
                }
            }).then((response) => {
                const cartAllItems = response.data.cart;
                let cartItems = this.state.cartItems
                cartItems = [];
                let errmsg = this.state.errmsg
                errmsg = '';
                cartAllItems.map(item => {
                    cartItems.push(item)
                })
                this.props.CartItems(cartItems)

                this.setState({ cartItems })
                this.setState({ showModal: false })
                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    // Object.keys(error.response.data.message).map(key => (
                    //     errmsg+=error.response.data.message[key] +"  "
                    //   ))
                    errmsg = error.response.data.message
                    this.setState({ show: true })
                    this.setState({ errmsg })

                }
                else {
                    this.setState({ errmsg: null })
                }
            })

    }
    //add to favourite function
    handleAddToFav = (preset_id) => {
        axios.post(`${serverPath}/api/add_to_favorit`, { preset_id: preset_id }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then((response) => {
                this.props.FAV(response.data.data)
                this.setState({ fav: response.data.data })
                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    this.setState({
                        errmsg: error.response.data.message, loading: false
                    })
                }
                else {
                    this.setState({ errmsg: null })
                }
            })
    }
    //create special order
    handleCreateSpecial = (details) => {
        let errmsg = this.state.errmsg
        errmsg = ''
        axios.post(`${serverPath}/api/make_special_order`, { details: details }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
            }
        })
            .then((response) => {
                this.setState({ showModal: false })

                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    this.setState({
                        errmsg: error.response.data.message, loading: false
                    })
                }
                else {
                    this.setState({ errmsg: null })
                }
            })
    }
    //show/hide alerts
    setShow = () => {
        const show = this.state.show
        this.setState({ show: !show })
    }
    render() {
        if (this.state.loading == false) {
            const wrapper = document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight = this.state.request.last_page > 1 ? '87px' : '32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`

        }
        //lisiting menu
        const presets = this.state.presets.length > 0 ? this.state.presets.map((preset, index) => {
            let presetPoints = 0;
            const fav = this.state.fav;
            const points = preset.items.length > 0 ? preset.items.map(item => {
                presetPoints += Number(item.pivot.points)
                return presetPoints
            }) : null
            return (
                <PresetListItem key={index} type="menu" preset={preset} handleShowModal={this.handleShowModal} handleAddToFav={this.handleAddToFav} fav={fav} preset_points={presetPoints} />
            )
        }) : (<Col>لا توجد بيانات</Col>)

        return (
            this.state.nopermission == true ? (<Redirect to='/nopermission' />) :
                (<div className='page-content'>


                    <div className='page-header'>
                        <Container fluid>
                            <Row>
                                <Col xs={'auto'}>
                                    <Button onClick={() => this.handleShowModal("special")} variant="primary" >أضف طلب خاص</Button>
                                </Col>
                                <Col>
                                    <SearchBar />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container fluid>
                        <div className='buffet-wrapper presets'>
                            <Planets customLoading={this.state.loading} />

                            <Row className='stretch'>
                                <Col xs={'12'}>
                                    <h1 className='title'>قائمة البوفيه</h1>
                                </Col>
                                {presets}
                            </Row>
                        </div>
                        {/* check is there pagination or not  */}
                        {this.state.request.last_page > 1 ?
                            <Pagination goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page} /> : null
                        }
                        {this.state.typeModal == 'special' ? (
                            <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {this.state.selectedItem.name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SpecialOrder
                                        create={this.handleCreateSpecial}
                                        show={this.state.show}
                                        errmsg={this.state.errmsg}
                                        id={this.props.id}
                                    />

                                </Modal.Body>

                            </Modal>
                        ) : (
                            <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {this.state.selectedItem.name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <AddToCart
                                        selectedId={this.state.selectedId}
                                        create={this.handleCreate}
                                        type={this.state.typeModal}
                                        preset={this.state.selectedItem}
                                        items={this.state.items}
                                        cats={this.state.categories}
                                        presetItems={this.state.presetItems}
                                        show={this.state.show}
                                        errmsg={this.state.errmsg}
                                        points={this.state.selectedPoints}
                                        setShow={this.setShow}
                                    />
                                </Modal.Body>

                            </Modal>
                        )}

                    </Container>

                    {this.state.alertDelete}
                    {this.state.alertSuccess}
                </div>)



        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        usertype: state.user.usertype,
        id: state.user.id,
        fav: state.user.fav
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        CartItems: (cartItems) => dispatch({ type: 'CARTITEMS', cartItems: cartItems }),
        FAV: (fav) => dispatch({ type: 'FAV', fav: fav })

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderMenu)
