import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import serverPath from '../config';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
function OuterOrderPage(props) {
  const [orders, setOrders] = useState();
  const [newOrder, setNewOrder] = useState({id: 0, title: '', count: 1 });
  const [isEditing, setIsEditing] = useState(false);
  const [editOrderId, setEditOrderId] = useState(null);

  // const navigate = useNavigate();
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${serverPath}/api/requests`, 
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+props.token
          }
        }
      );
      const data = await response.json();
      setOrders(data);
      if(data.message) {
        // setShow(true)
        window.location.href = '/';
      }
      // console.log(data)

    } catch (error) {
      console.log('Error fetching orders:', error.message);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleOrderSubmit = async (event) => {
    event.preventDefault();

    if (isEditing) {
      // Update existing order
      try {
        await axios.post(`${serverPath}/api/update_request`, newOrder, {
          headers: { 
            'Authorization':'Bearer '+props.token
          },
        })
        .then((res) => {
          console.log('res',res)
          const updatedOrder = res.json();
          setOrders(orders.map(order => order.id === updatedOrder.id ? updatedOrder : order));
          setIsEditing(false);
          setEditOrderId(null);
          fetchOrders();
        })
      } catch (error) {
        setIsEditing(false);
        setEditOrderId(null);
        console.error('Error updating order:', error);
        fetchOrders();
      }
    } else {
      // Create new order
      try {
        await axios.post(`${serverPath}/api/requests`,newOrder, {
          headers: { 
            'Authorization':'Bearer '+props.token
          },
        })
        .then((res) => {
          fetchOrders();
        })
        console.log('newOrder',newOrder)
        setOrders([...orders, newOrder]);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    }

    // Reset form
    setNewOrder({ title: '', count: 1 });
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.post(`${serverPath}/api/delete_request/${orderId}`,{}, {
        headers: { 
          'Authorization':'Bearer '+props.token
        },
      })
      .then((res) => {
        fetchOrders();
      })
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleEditOrder = (order) => {
    setIsEditing(true);
    setEditOrderId(order.id);
    setNewOrder({id: order.id, title: order.title, count: order.count });
  };

  return (
    // ... rest of your component HTML structure ...
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6">
            <div class="card-body">
              <form onSubmit={handleOrderSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">اسم الطلب</label>
                  <input type="text" placeholder='ادخل اسم الطلب' className="form-control" id="name" value={newOrder.title} onChange={(e) => setNewOrder({ ...newOrder, title: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="count" className="form-label">عدد الطلبات</label>
                  <input type="number" className="form-control" id="count" value={newOrder.count} onChange={(e) => setNewOrder({ ...newOrder, count: parseInt(e.target.value) })} />
                </div>
                <button type="submit" className="btn btn-primary">{isEditing ? 'تعديل الطلب' : 'أضف'}</button>
              </form>
            </div>
          </div>
          <div className="col-lg-12">
          <table className="table table-bordered table-dark">
            <thead>
              <th style={{width: '150px'}}>العدد</th>
              <th style={{width: '440px'}} >الصنف</th>
              <th >تعديل</th>
              <th >حذف</th>
            </thead>
            <tbody>
              {orders?.data?.map(order => (
                <tr key={order.id}>
                  <td>{order.count}</td>
                  <td>{order.title}</td>
                  <td>
                    <button className="btn btn-sm btn-warning" onClick={() => handleEditOrder(order)}>
                      تعديل الطلب
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                      </svg>
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-sm btn-danger d-flex align-items-center gap-4 " onClick={() => handleDeleteOrder(order.id)}>
                      حذف الطلب
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps=(state)=>{
  return{
      token:state.auth.token,
  }
}

export default connect(mapStateToProps) (OuterOrderPage)
// export default OuterOrderPage;