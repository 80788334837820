import authReducer from './authReducer'
import departmentsReducer from './departmentsReducer'
import userReducer from './userReducer'
import {combineReducers} from 'redux'

const rootReducers= combineReducers({
    auth: authReducer,
    user:userReducer,
    departments: departmentsReducer,
    
});
export default rootReducers