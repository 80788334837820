import React, { Component } from 'react'
import { Button , Modal,Alert , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import { Planets } from 'react-preloaders';
import SearchBar from '../layout/SearchBar'
class EmployeeOwnItems extends Component{
    state={
        myItems:[],
        errmsg:'',
        show:'',
        loading:true,
    }
   async componentDidMount(){
        await this.fillData()
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        wrapper.style.height = `calc(100vh -   ${offset} px)`

}
    fillData(){
        Promise.all([
          
            axios.get(`${serverPath}/api/my_items`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            })
           
        ])
        .then(([responseOne])=>{
            
            const myItems= responseOne.data.data;
           
            this.setState({myItems,loading:false})  

        })
        .catch(errors => {
            this.setState({errmsg:errors.message})
            if(errors.response.status==403){
                this.setState({nopermission:true})
            }
        })
    }
    setShow = ()=>{
        const show = false
        this.setState({show})
      }
    render(){
        const items=this.state.myItems.length>0  ? this.state.myItems.map((item,index)=>{
            return (
                    <div key={index} className='list-item'>
                            <h4 className='item-name'>{item.item.name}</h4>
                            <h4 className='item-text'>الكمية المتبقية</h4>
                            <h3 className='item-quantity'>{item.quantity} {item.item.label}</h3>
                        </div>
                    )
        }):(<div>لا توجد منتجات تخصك</div>)
        return(
            <div className='page-content'>
                 <div className='page-header'>
                    <Container fluid>
                        <Row>
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>                   
                </div>
                <Container fluid>
                    
                    <div className='buffet-wrapper myItems'>

                        <Planets customLoading={this.state.loading}/>
                            {items}
                    </div>     
                {this.state.errmsg ?(
                    
                    <Alert show={this.state.show} variant="danger" dismissible onClose={this.setShow}>
                     
                        <span className='cursor' onClick={this.handleLogout}>{this.state.errmsg}</span>
                    </Alert>) : null} 
                </Container>
            </div>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
    }
}

export default connect(mapStateToProps) (EmployeeOwnItems)