import React, {Component} from 'react';
import axios from 'axios';
import serverPath from '../config';
import Modal from 'react-bootstrap/Modal'
import {Button, Alert, Row, Container, Col} from 'react-bootstrap';
import {connect} from 'react-redux'
import UserGroupForm from './UserGroupForm'
import SweetAlert from 'react-bootstrap-sweetalert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash,faPen} from '@fortawesome/free-solid-svg-icons'
import Planets from 'react-preloaders/lib/Planets/Planets';
import SearchBar from '../layout/SearchBar'
import  { Redirect } from 'react-router-dom'

class AllUsersGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: '',
            group: '',
            selectedGroupUsers: [],
            selectedGroupPermissions: [],
            permisssions: [],
            isEdit: false,
            group_id: '',
            errmsg: '',
            cerrmsg: '',
            show: true,
            users: [],
            alertDelete: '',
            alertSuccess: '',
            loading: true,
            typeModal:'',
            nopermission:false,
            showModal:false
        }
        this._isMounted = false;
    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    async componentDidMount() {
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        wrapper.style.height = 'calc(100vh - ' + offset + 'px - 40px)'
        await this.fillData()
    }
    fillData =()=>{
        Promise.all([
            axios.get(`${serverPath}/api/UserType`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
            axios.get(`${serverPath}/api/User`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
            axios.get(`${serverPath}/api/Permission`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            })
        ])
        .then(([responseOne,responseTwo,responesThree])=>{
            const groups = responseOne.data.data;
            const users = responseTwo.data.data.data;
            const permissions = responesThree.data.data;
            this.setState({permissions,loading:false,users,groups})  
        })
        .catch(errors => {
            this.setState({cerrmsg:errors.message})
            if(errors.response.status==403){
                this.setState({nopermission:true})
            }
        })
    }
    handleDelete = (id) => {
        let errmsg = this.state.errmsg
        const headers = {
            'Authorization': 'Bearer ' + this.props.token
        }

        axios
            .delete(`${serverPath}/api/UserType/${id}`, {headers: headers})
            .then((response) => {
                const groups = this
                    .state
                    .groups
                    .filter(group => {
                        return group.id !== id
                    })
                this.setState({groups})
                this.showAlertSuccess()
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                } else {
                    this.setState({errmsg: null})
                }
            })
        this.setState({alertDelete: ''})
    }
    showAlertDelete = (id) => {
        this.setState({
            alertDelete: <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="حذف"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    cancelBtnText='رجوع'
                    title="هل انت متأكد من مسح المجموعة ؟"
                    onConfirm={() => this.handleDelete(id)}
                    onCancel={this.onCancel}></SweetAlert>
        })
    }
    onCancel = () => {
        this.setState({alertDelete: ''})
    }
    onCancelSuccess = () => {
        this.setState({alertSuccess: ''})
    }
    handleShowModal = (type,id, group, permissions) => {
        this.setState({typeModal:type,group_id: id, group: group, selectedGroupPermissions: permissions,showModal:true})
    }
    handleCreate = (group, permissions) => {
        let errmsg = this.state.errmsg
        errmsg = '';
        axios.post(`${serverPath}/api/UserType`, {
            type: group,
            permissions: permissions
        }, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Accept': 'application/json'
            }
        }).then((response) => {
            const groups = this.state.groups;
            groups.push(response.data.data)
            this.setState({groups,showModal: false})
            this.showAlertSuccess()
        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})

            } else {
                this.setState({errmsg: null})
            }
        })
    }
    showAlertSuccess = () => {
        this.setState({
            alertSuccess: <SweetAlert
                    success
                    confirmBtnText="تم"
                    confirmBtnBsStyle="primary"
                    title="تم تنفيذ طلبك بنجاح !"
                    timeout={2000}
                    onConfirm={this.onCancelSuccess}
                    onCancel={this.onCancelSuccess}></SweetAlert>
        })
    }
    handleEdit = (permissions, type, group_id) => {
        let groups = this.state.groups;
        let errmsg = this.state.errmsg
        errmsg = '';
        axios.post(`${serverPath}/api/UserType/update_user_type/${group_id}`, {
            permissions: permissions,
            type: type
        }, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({showModal: false})
            for (let i in groups) {

                if (groups[i].id == group_id) {
                    groups[i].permissions = []
                    groups[i].type=response.data.data.type
                    for (let x in response.data.data.permissions) {
                        groups[i]
                            .permissions
                            .push(response.data.data.permissions[x])
                    }
                    break; //Stop this loop, we found it!

                }
                this.setState({groups})
            }
            this.showAlertSuccess()

        }, (error) => {
            if (error.response) {
                error.response.data.errors.map(err => (errmsg += err + " . "))
                this.setState({show: true,errmsg})

            }else {
                this.setState({errmsg: null})
            }
        });
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }
    setShow = () => {
        this.setState({show:false})
    }
    render() {
        const permissions = this.props.permissions;

        const groups = this.state.groups
        const allgroups = groups.length
            ? (groups.map(group => {
                return (
                    <li key={group.id} className='collection-item'>
                        <span>{group.type}
                        </span>
                        <div className='record-control'>
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'edit-usertype'
                                        ? (
                                            <Button key={index}
                                                variant="success"
                                                onClick={() => this.handleShowModal('edit',group.id, group.type, group.permissions)}>
                                                <FontAwesomeIcon icon={faPen}/>
                                            </Button>
                                        )
                                        : (null))
                                }))
                                : null}
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'delete-usertype'
                                        ? (
                                            <Button key={index} variant="danger" onClick={() => this.showAlertDelete(group.id)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        )
                                        : (null))
                                }))
                                : null}

                        </div>
                    </li>
                )
            }))
            : <p className='collection-item'>لا توجد بيانات
            </p>;

        return (
            this.state.nopermission==true?( <Redirect to='/nopermission'  />):(
            <div className='page-content'>
                <div className='page-header'>
                    <Container fluid>
                        <Row>
                            {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-usertype'
                                        ? (
                                            <Col key={Math.random()} xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal('create')}>أضف مجوعة جديدة</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <ul className="collection buffet-wrapper">
                        <Planets customLoading={this.state.loading}/> {allgroups}
                    </ul>
                    {this.state.cerrmsg
                        ? (

                            <Alert
                                show={this.state.show}
                                variant="danger"
                                dismissible
                                onClose={this.setShow}>

                                <span>{this.state.cerrmsg}</span>
                            </Alert>
                        )
                        : null}
                </Container>
                <Modal
                    animation={false}
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.typeModal=='create'?('إضافة مجموعة جديد'):('تعديل مجموعة ')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserGroupForm
                            setShow={this.setShow}
                            show={this.state.show}
                            type={this.state.typeModal}
                            errmsg={this.state.errmsg}
                            permissions={this.state.permissions}
                            createUserGroup={this.handleCreate}
                            token={this.props.token}
                            currentPermissions={this.state.selectedGroupPermissions}
                            usergroup={this.state.group}
                            editUsergroup={this.handleEdit}
                            id={this.state.group_id}/>
                            
                    </Modal.Body>

                </Modal>
                
                {this.state.cerrmsg
                    ? (
                        <Alert
                            show={this.state.show}
                            variant="danger"
                            dismissible
                            onClose={this.setShow}>

                            <span className='cursor' onClick={this.handleLogout}>{this.state.cerrmsg}</span>
                        </Alert>
                    )
                    : null}
                {this.state.alertSuccess}
                {this.state.alertDelete}

            </div>
)
        )

    }
}
const mapStateToProps = (state) => {
    return {token: state.auth.token, permissions: state.user.permissions, usertype: state.user.usertype}
}

export default connect(mapStateToProps)(AllUsersGroups)
