import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
//this component recieves modaltype as a prop to determine which function will be excute
class EmployeesItemForm extends Component{
    constructor(props){
        super(props);
        this.state={
            //these states will be filled when component loaded if modal type is edit otherwise it will fill on change inputs
            item_id:this.props.item_id?this.props.item_id:'',
            quantity:this.props.quantity?this.props.quantity:'',
            user_id:this.props.user_id?this.props.user_id:'',
            buffet_items:this.props.buffet_items,
            users:this.props.users,
            usersList:[]
        }
    }
    componentDidMount(){
        const usersList=this.state.usersList
        this.props.users.map(user=>{
            usersList.push({value:user.id,label:user.fullname})
        })
        this.setState({usersList})
    }
    handleChange =(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
   
    
    handleSubmit = (e)=>{
        e.preventDefault();
        const item_id = this.state.item_id
        const user_id= this.state.user_id
        const quantity= this.state.quantity
        this.props.type =='add'? this.props.createItem(item_id,user_id,quantity):this.props.editItem(item_id,quantity,user_id)
        this.props.setShow()
    }
    onSelectChange = user_id => {
        this.setState({user_id:user_id.value});
      };

    render(){
       const buffet_items=this.state.buffet_items.length>0?this.state.buffet_items.map((item,index)=>{
           return (
            <option value={item.id} key={index}>
             {item.name}
             </option>
           )
       }):null
       const users=this.state.users.length>0?this.state.users.map((user,index)=>{
           return (
            <option value={user.id} key={index}>
             {user.fullname}
             </option>
           )
       }):null
       
        return(
            <Form  id="userform" className='text-right' onSubmit={this.handleSubmit}>
                {this.props.type=='add'?(
                    <>
                    <Form.Group controlId="name">
                <label htmlFor="name">اسم الموظف</label>
                {/* react select docs  */}
                <Select
                closeMenuOnSelect={true}
                isSearchable                
                components={animatedComponents}
                defaultValue={this.state.user_id}
                value={this.state.user_id.label}
                options={this.state.usersList}
                onChange={this.onSelectChange}
                className='form-control select-form'
                classNamePrefix="select"
                />
                </Form.Group>
                <Form.Group controlId="item_id">
                    <label htmlFor="item_id">اسم المنتج</label>
                    <Form.Control name='item_id' defaultValue={this.state.item_id} onChange={this.handleChange}  as="select">
                        <option  value=""> إختر المنتج</option>
                        {buffet_items}
                    </Form.Control>                 
                </Form.Group>
                    </>
                ):(null)}
                
                
                <Form.Group controlId="quantity">
                <label htmlFor="quantity">الكمية</label>
                    <Form.Control  required name='quantity' value={this.state.quantity} min="0"  type="number" onChange={this.handleChange} placeholder="الكمية" />
                </Form.Group>
               
                <Form.Group className='text-center'>
                     <Button  variant="primary" type="submit">
                      حفظ
                    </Button>
                </Form.Group>
               
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default EmployeesItemForm