import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
class ReasonForm extends Component{
    
        state={
            review:this.props.reason.review?this.props.reason.review:'',
        }
    
    
    handleChange =(e)=>{
        this.setState({review:e.target.value})
        
    }
   
    
    handleSubmit = (e)=>{
        e.preventDefault();
        this.props.type =='add'? this.props.createReason(this.state.review):this.props.editReason(this.state.review,this.props.reason.id)

    }
    

    render(){
       
        return(
            <Form  id="userform" className='text-center' onSubmit={this.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Control required name='review' type="text" value={this.state.review} onChange={this.handleChange} placeholder="اسم التقييم" />
                </Form.Group>

                <Button  variant="primary" type="submit">
                    حفظ
                </Button>
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default ReasonForm