import React, { Component } from 'react';
import { Button , Alert , Container,Col,Row } from 'react-bootstrap';
import nopermission from '../../assets/img/nopermission.gif'

class NoPermission extends Component{
    componentDidMount(){
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset = wrapper.offsetTop
        wrapper.style.height = 'calc(100vh - ' + offset + 'px - 40px)'
    }
    render(){
        return(
            <div className="NoPermission">
                 <Container fluid>
                    <div className='buffet-wrapper' style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                        
                            <img src={nopermission} alt=""/>
                            <Alert
                                show={true}
                                variant="danger">
                                <span>عفوا ليس لديك صلاحية للدخول ارجو التواصل مع مدير البوفيه</span>
                            </Alert>
                   </div>
                </Container>
            </div>
        )
        
          
    }
}
export default NoPermission