import React, { Component } from 'react'
import { Button , Alert,Modal , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import itemImg from '../../assets/img/defaultItem.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash ,faPen} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import AddToCart from './AddToCart'
import SearchBar from '../layout/SearchBar'


class Cart extends Component{
    constructor(props){
        super(props);
        this.state={
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            errmsg:'',
            cerrmsg:'',
            show:'',
            alert:'',
            alertSuccess:'',
            loading:true,
            cartItems:this.props.cartItems?this.props.cartItems:[],
            cartId:'',
            selectedItems:'',
            selectedQty:'',
            selectedQty2:'',
            selectedPrivatec:[],
            selectedNotes:'',
            newData:''
        }
    }
   async componentDidMount(){
    await axios.get(`${serverPath}/api/get_cart/${this.props.id}`, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const cartAllItems= response.data.cart;
        const cartItems=[]
        cartAllItems.map(item=>{
            cartItems.push(item)
        })
       this.setState({cartItems,loading:false})
    },(error)=>{
        if(error.response.status === 403){
            this.props.history.push('/nopermission')
         }
        if(error.response){
            this.setState({cerrmsg : error.response.data.message,loading:false,show:true
            })  
        }
        else{
          this.setState({cerrmsg : null})
        }
      })
    
     
}
checkOut=()=>{
     axios.post(`${serverPath}/api/make_order`,{user_id:`${this.props.id}`}, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        this.props.ClearCart('')
        this.setState({cartItems:'',alert:''})
        this.showAlertSuccess(response.data.message)
       
     
     
    },(error)=>{
        if(error.response){
            this.setState({cerrmsg : error.response.data.message,alert:'',loading:false,show:true
            })  
        }
        else{
          this.setState({cerrmsg : null})
        }
      })
}
handleDelete=(id)=>{
    const headers=   {
        'Authorization':'Bearer '+this.props.token
    }
    const cartItems = this.state.cartItems.filter(item=>{
        return item.id !== id
    })
    axios.get(`${serverPath}/api/delete_cart/${id}`, {headers: headers}).then((response)=>{
        this.showAlertSuccess(response.data.message)
        this.setState({cartItems:response.data.cart})
        this.props.CartItems(response.data.cart)

    }, (error) => {
        if(error.response){
            this.setState({errmsg : error.response.data.message})
            this.setState({show : true})   
        }
        else{
          this.setState({errmsg : null})
        }
      })
    this.setState({alert:''})
} 
clearCart=()=>{
    
    axios.get(`${serverPath}/api/empty_cart`, {
       headers: {
           'Accept': 'application/json',
           'Authorization':'Bearer '+this.props.token
       }
   })
   .then((response) => {
       this.props.CartItems('')
       this.setState({cartItems:[]})
       this.showAlertSuccess(response.data.message)
   },(error)=>{
       if(error.response){
           this.setState({cerrmsg : error.response.data.message,loading:false,show:true
           })  
       }
       else{
         this.setState({cerrmsg : null})
       }
     })
}
showAlert=(type,id)=>{
    type=='delete'?
    this.setState({
        alert:<SweetAlert
        danger
        showCancel
        confirmBtnText="حذف"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="primary"
        cancelBtnText='رجوع'
        title="هل انت متأكد من مسح المشروب من السلة ؟"
        onConfirm={()=>this.handleDelete(id)}
        onCancel={this.onCancel}
      >
    </SweetAlert>
    }):
    this.setState({
        alert:<SweetAlert
        reverseButtons
        success
        showCancel
        confirmBtnText="اتمام الطلب"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        cancelBtnText='إلغاء'
        title="هل انت متأكد من إتمام الطلب ؟"
        onConfirm={this.checkOut}
        onCancel={this.onCancel}
      >
    </SweetAlert>
    })
} 
onCancel = ()=>{
    this.setState({alert:''})
}
handleShowModal=(type,cartId,id,item,items,points,notes)=>{

    this.setState({cartId:cartId,selectedPoints:points,showModal:true,typeModal:type,selectedId:id?id:'',selectedItem:item?item:'',selectedItems:items?items:'',selectedNotes:notes?notes:''})
}
showAlertSuccess=(msg)=>{
    this.setState({
        alertSuccess:<SweetAlert
        success
        confirmBtnText="تم"
        confirmBtnBsStyle="primary"
        title={msg}
        timeout={2000}
        onConfirm={this.onCancelSuccess}
        onCancel={this.onCancelSuccess}
      >
      </SweetAlert>
    })
}
onCancelSuccess = ()=>{
    this.setState({alertSuccess:''})
}
handleCloseModal=()=>{
    this.setState({showModal:false,typeModal:'',selectedId:'',errmsg:''})
}
handleEdit = (cartId,presetId,items,quantity,quantity2,privatec,notes)=>{
    let errmsg=this.state.errmsg
    errmsg = ''
    axios.post(`${serverPath}/api/update_cart/${cartId}`,
       {
            presit_id:presetId,
            items:items,
            quantity:quantity,
            quantity2:quantity2,
            mine:privatec,
            notes:notes
        }

    ,{
        headers:{
            'Authorization':`Bearer ${this.props.token}`,
            'Accept': 'application/json',
        }
    }).then((response)=>{
        const cartAllItems= response.data.cart;
        let cartItems=this.state.cartItems
        cartItems=[];
        cartAllItems.map(item=>{
            cartItems.push(item)
        })
        this.props.CartItems(cartItems)

        this.setState({cartItems})
        this.setState({showModal:false})
        this.showAlertSuccess(response.data.message)
    }, (error) => {
        if(error.response){
                errmsg = error.response.data.message
            // errmsg=error.response.data.message
            this.setState({show : true})   
            this.setState({errmsg})   

        }
        else{
        this.setState({errmsg : null})
        }
    })
    
}
setShow = ()=>{
    const show = false
    this.setState({show})
  }
    render(){
        const carts = this.state.cartItems.length>0?(
            this.state.cartItems.map(item=>{
                return(
                    <div className='cart-row' key={item.id}>
                        <span className='item-name'>
                            <img src={item.preset.image?item.preset.image:itemImg} className="cartItem-img"/>
                            {item.preset.name?item.preset.name:'عنصر غير موجود'}
                        </span>
                        <div className='item-controls'>
                        {item.preset.name?
                            (<span className='item-price'>
                                النقاط : {item.points} نقاط
                            </span>):null}
                            <span className='cart-btns'>
                            {item.preset.name?(
                                <Button onClick={()=>this.handleShowModal("edit",item.id,item.presit_id,item.preset,item.cart_items,item.points,item.notes)} className='cart-btn' variant="success" >
                                <FontAwesomeIcon  icon={faPen}/>
                            </Button>
                            ):null}
                                
                                <Button onClick={()=>this.showAlert('delete',item.id)} className='cart-btn' variant="danger" >
                                    <FontAwesomeIcon  icon={faTrash}/>
                                </Button>
                            </span>
                        </div>
                    </div>
                )
                
            })
        ):(<div className='cart-row noitems'>
            لا توجد طلبات جديدة في السلة ليتم تنفيذها.
        </div>)
               return(
            <div className='page-content'>
                 <div className='page-header'>
                 <Container fluid>
                    <Row>
                    <Col>
                        <SearchBar/>
                    </Col>
                    </Row>
                    </Container>                  
                </div>
                <Container fluid>
                    <div className='buffet-wrapper cart'>
                        <Planets customLoading={this.state.loading} />
                        <Row className='stretch'>
                        <Col xs={12}>
                        <h1 className='title'>السلة</h1>
                        </Col>
                        <Col xs={12}>
                            <div className='cart-content'>
                                {carts}
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className='checkout-btns'>
                                    <Button variant="primary" onClick={()=>this.showAlert('checkout')}>تنفيذ الطلب</Button>
                                    <Button onClick={this.clearCart} variant='danger'>تفريغ السلة</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {this.state.cerrmsg ?(
                    
                    <Alert show={this.state.show} variant="danger" dismissible onClose={this.setShow}>
                    
                        <span>{this.state.cerrmsg}</span>
                    </Alert>) : null} 
                        <Modal   animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.selectedItem.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <AddToCart 
                        selectedId={this.state.selectedId}
                        cartId={this.state.cartId}
                        edit={this.handleEdit}
                        type={this.state.typeModal}
                        preset={this.state.selectedItem}              
                        presetItems={this.state.selectedItems}              
                        show={this.state.show}
                        setShow={this.setShow}
                        errmsg={this.state.errmsg}
                        points={this.state.selectedPoints}
                        notes={this.state.selectedNotes}
                        />
                    </Modal.Body>
              
                </Modal>
                    
                </Container>
                {this.state.alertSuccess}
                  {this.state.alert}
                </div>
            
                   
               
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        id:state.user.id,
        cartItems:state.user.cartItems
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      CartItems: (cartItems)=>dispatch({type:'CARTITEMS', cartItems:cartItems}),  
      ClearCart:(cartItems)=>dispatch({type:'CLEARCART',cartItems:cartItems})

    }
  }

export default connect(mapStateToProps,mapDispatchToProps) (Cart)