import React, { Component } from 'react'
import axios from 'axios'
import  { connect } from 'react-redux';
import serverPath from '../config'
import { Button , Container ,Row ,Col,Alert} from 'react-bootstrap';
import './Signin.scss'
import logo from '../../assets/img/Logo_background.svg'
import loginimg from '../../assets/img/login-chr.svg'
 class SignIn extends Component {
    state={
        username:"",
        password:"",
        errmsg:'',
        show:true
    }
    // submit login info then store token , fav list and other user details
    handleSubmit =(e)=>{
      let errmsg=this.state.errmsg
        e.preventDefault();
        axios.post(serverPath+'/api/login', {
          username: this.state.username,
          password: this.state.password},{
          headers: {
            'Content-Type': 'application/json',
        },
          
        })
        .then((response) => {
          //redux store
          this.props.UID(response.data.data.id);
          this.props.Login(response.data.token);
          this.props.Permissions(response.data.permissions);
          this.props.UserType(response.data.data.user_type_id);
          this.props.UserName(response.data.data.username);
          this.props.FAV(response.data.favorits);
          this.props.ProfilePic(response.data.data.profile_pic);
          this.props.points(response.data.data.points);
          this.props.credit(response.data.data.credit);
        }, (error) => {
          if (error.response) {
                errmsg=error.response.data.data;
                this.setState({show: true,errmsg})
            }
          else{
            this.setState({errmsg : null})
          }
        });
    }
    // this for storing username & password on state to use it in submitting login
    handleChange =(e)=>{
        this.setState({
            [e.target.id]:e.target.value
        })
    }
    // setshow is a method for showing alerts
    setShow = ()=>{
      const show = false
      this.setState({show})
    }
    render() {
        return (
          <div className='signin-page'>
            <Container className='relative'>  
              <Row className='flex-row-reverse'>
              <div className='signin-header tv text-center'>
                    <img src={logo} alt='Dimofinf buffet system'/> 
                    <h5 className="signin-heading">مرحبا بك في البوفية</h5>
                  </div>
              <Col xl={5} xs={12}  className='text-center'>
                  <img src={loginimg} className='img-fluid login-img' alt="dimofinf buffet"/>
                </Col>
                <Col xl={{span: 6, offset: 1}}  xs={12}>
                  <div className='signin-header cv'>
                    <img src={logo} alt='Dimofinf buffet system'/> 
                    <h5 className="signin-heading">مرحبا بك في البوفية</h5>
                  </div>
                  
        
                  <form  onSubmit={this.handleSubmit}>
                    <div className="input-field">
                        <input autoComplete="off" type="text" id="username" onChange={this.handleChange} required />
                        <label htmlFor="email">اسم المستخدم</label>

                    </div>
                    <div className="input-field">
                        <input autoComplete="off" type="password" id="password" onChange={this.handleChange} required />
                        <label htmlFor="password">كلمة المرور</label>

                    </div>
                    <div className="input-field">
                    <Button type='submit' variant="primary" className='pull-right'>
                    تسجيل الدخول
                    </Button>
                    
                    </div>

                </form>   
                {/* here is how error alert showing   */}
                {this.state.errmsg && this.state.show?(
                  
                    <Alert  variant="danger" dismissible onClose={this.setShow}>
                     
                      {this.state.errmsg}
                    </Alert>) : null}         
                </Col>
                
             </Row>
            </Container>
          </div>
            
        )
    }
}
const mapStateToProps = (state)=>{
    return {
      token:state.auth.token,
      usertype:state.user.usertype,
      username:state.user.username,
      profilePic:state.user.profilePic,
      permissions:state.user.permissions,
      fav:state.user.fav
    }
  }
  const mapDispatchToProps=(dispatch)=>{
    return{
      Login: (token)=>dispatch({type:'LOGIN', token:token}),
      Permissions:(permissions)=>dispatch({type:'PERMISSIONS',permissions:permissions}),
      UID:(id)=>dispatch({type:'UID',id:id}),
      UserType:(usertype)=>dispatch({type:'USER_TYPE',usertype:usertype}),
      UserName:(username)=>dispatch({type:'USERNAME',username:username}),
      ProfilePic:(profilePic)=>dispatch({type:'PROFILE_PIC',profilePic:profilePic}),
      points: (points)=>dispatch({type:'POINTS', points:points}),
      credit: (credit)=>dispatch({type:'CREDIT', credit:credit}),
      FAV:(fav)=>dispatch({type:'FAV',fav:fav}),      
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(SignIn)
