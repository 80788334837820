const initstate={
    departments:'',
    department:''
}

const departmentsReducer = (state = initstate , action)=> {
   if(action.type ==='ALL_DEPARTMENTS'){
    return{
        ...state,
        departments:action.departments
    }
   }
  
    return state
}
export default departmentsReducer