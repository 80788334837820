import React, { Component } from 'react'
import { Button , Form , Alert ,ListGroup } from 'react-bootstrap';

class UserGroupForm extends Component{
    state={
        usergroup:this.props.usergroup?this.props.usergroup:'',
        permissions:this.props.permissions?this.props.permissions:'',
        selectedPermissions:this.props.currentPermissions?this.props.currentPermissions:[],
        permissions_id:[]
    } 
    componentDidMount(){

        const permissions_id= this.state.permissions_id;
        const selectedPermissions = this.state.selectedPermissions;
        if(selectedPermissions){
            selectedPermissions.map(permission=>{
         permissions_id.push(permission.id)
         this.setState({permissions_id})
        })
    }
       
    }
    handleChange =(e)=>{
        this.setState({usergroup:e.target.value})
    }
    handleSubmit = (e)=>{
        e.preventDefault();
      const permissions_id= this.state.permissions_id
        const usergroup = this.state.usergroup;
        this.props.type=='create'?
        this.props.createUserGroup(usergroup,permissions_id):this.props.editUsergroup(permissions_id,usergroup,this.props.id)
    }
    setShow = ()=>{
        const show = false
        this.setState({show})
      }
      handleCheckChieldElement=(e)=>{
        const isChecked = e.target.checked;
        const permissions_id=this.state.permissions_id
        if(isChecked){
            permissions_id.push(parseInt(e.target.id))
            this.setState({permissions_id})
        }
        else{
            const index = permissions_id.indexOf(parseInt(e.target.id));

            if (index > -1) {

                permissions_id.splice(index, 1);
              }

            this.setState({permissions_id})
        }
      }
      handleToggle=(e)=>{
        const isChecked_id = e.target.id;
        const curPermissions= this.state.selectedPermissions.filter(permission=>{
            return (permission.id!=isChecked_id)
        });
        this.setState({selectedPermissions:curPermissions})
      }
    render(){
        const allPermissions= this.props.permissions;
    
        const curPermissions= this.state.selectedPermissions;
        const listPermissions= allPermissions?allPermissions.map((permission)=>{
        return(
            curPermissions.some(cpermission => cpermission.id === permission.id) ? (<ListGroup.Item key={permission.id}>
                <input  id={permission.id} checked="checked" onClick={this.handleToggle} onChange={this.handleCheckChieldElement} type="checkbox" value={permission.permission} /> 
                <label htmlFor={permission.id}>{permission.permission}</label></ListGroup.Item>):(<ListGroup.Item key={permission.id}>
                    <input  id={permission.id}  onChange={this.handleCheckChieldElement} type="checkbox" value={permission.permission} /> 
                    <label htmlFor={permission.id}>{permission.permission}</label>
                    </ListGroup.Item>)          
    )}):null
       
        return(
            <Form className='permissions text-center' onSubmit={this.handleSubmit}>
                <Form.Group controlId="addusergroup">
                    <Form.Control pattern="[أ-يa-zA-Z\s]+" type="text" value={this.state.usergroup} onChange={this.handleChange} required placeholder="اسم المجموعة" />
                    <h3>إختر الصلاحيات</h3>
                    <ListGroup>
                        {listPermissions}
                    </ListGroup>
                </Form.Group>

               
                <Button  variant="primary" type="submit">
                    حفظ
                </Button>
                {this.props.errmsg && this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )
    }
}
export default UserGroupForm