import React, {Component} from 'react';
import axios from 'axios';
import serverPath from '../config';
import Modal from 'react-bootstrap/Modal'
import {Button, Alert, Row, Col, Container} from 'react-bootstrap';
import {connect} from 'react-redux'
import UserForm from './UserForm'
import SweetAlert from 'react-bootstrap-sweetalert';
import {faTrash, faBan, faCheck, faPen, faPrint} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Planets} from 'react-preloaders';
import SearchBar from '../layout/SearchBar'
import Pagination from '../dashboard/Pagination'
import ReactToPrint from "react-to-print";
class QrCode extends Component {
    render() {
      return (
        <div className='print-qr'>
            <img src={this.props.pic}/>
        </div>
      );
    }
  }
class AllUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: '',
            user: '',
            userId: '',
            cerrmsg: '',
            errmsg: '',
            show: true,
            departments: '',
            groups: '',
            alert: '',
            alertSuccess: '',
            loading: true,
            typeModal: '',
            suspendedId: '',
            request:'',
            currentPage:1
        }

    }
    async componentDidMount() {

        this._isMounted = true
        if (this._isMounted) {
            
            await this.fillData(this.state.currentPage);
        }
    }
    //fill data function all get requests on load
    fillData(page){
        Promise.all([
            //get users
            axios.get(`${serverPath}/api/User?page=${page}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
           //get usertypes
            axios.get(`${serverPath}/api/UserType`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            }),
            //get departments
            axios.get(`${serverPath}/api/Department`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+this.props.token
                }
            })
        ])
        .then(([responseOne,responseTwo,responesThree])=>{
            
            const users= responseOne.data.data.data;
            const request= responseOne.data.data;
            const groups= responseTwo.data.data;
            const departments= responesThree.data.data;
            this.setState({departments,loading:false,request,users,groups})  
    
        })
        .catch(errors => {
            this.setState({errmsg:errors.message})
            if(errors.response.status==403){
                this.setState({nopermission:true})
            }
        })
    }
    goToPage =(page)=>{
        this.fillData(page)
        this.setState({currentPage:page})
    }
    //close sweet aleert danger
    onCancel = () => {
        this.setState({alert: ''})
    }
    //close sweet alert success
    onCancelSuccess = () => {
        this.setState({alertSuccess: ''})

    }
    //show sweet alert on success
    showAlertSuccess = (msg) => {
        this.setState({
            alertSuccess: <SweetAlert
                    success
                    confirmBtnText="تم"
                    confirmBtnBsStyle="primary"
                    title={msg}
                    timeout={2000}
                    onConfirm={this.onCancelSuccess}
                    onCancel={this.onCancelSuccess}></SweetAlert>
        })
    }
        //show sweet alert on delete or suspend
    showAlert = (type, id, username) => {
        switch (type) {
            case 'delete':
                this.setState({
                    alert: <SweetAlert
                            danger
                            showCancel
                            confirmBtnText="حذف"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            cancelBtnText='رجوع'
                            title={' هل انت متأكد من مسح ' + (username) + ' ؟ '}
                            onConfirm={() => this.handleDelete(id)}
                            onCancel={this.onCancel}></SweetAlert>
                })
                break;
                //when suspend any user
            case "suspend":
                this.setState({
                    alert: <SweetAlert
                            danger
                            showCancel
                            reverseButtons
                            confirmBtnText="نعم"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            cancelBtnText='رجوع'
                            title={' هل انت متأكد من ايقاف ' +  (username)  + ' ؟ '}
                            onConfirm={() => this.handleToggleSuspend(id)}
                            onCancel={this.onCancel}></SweetAlert>
                })
                break;
            default:
                //when activate user and remove suspend
                this.setState({
                    alert: <SweetAlert
                            danger
                            reverseButtons
                            showCancel
                            confirmBtnText="نعم"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            cancelBtnText='رجوع'
                            title={'  هل انت متأكد من تفعيل ' + (username) + ' ؟ '}
                            onConfirm={() => this.handleToggleSuspend(id)}
                            onCancel={this.onCancel}></SweetAlert>
                })
        }

    }
    //delete single  user
    handleDelete = (id) => {
        let errmsg=this.state.errmsg
        const headers = {
            'Authorization': 'Bearer ' + this.props.token,
            'Accept': 'application/json'
        }
        const users = this.state.users
            .filter(user => {
                return user.id !== id
            })
        this.setState({users})
        this.setState({alert: ''})

        axios.delete(`${serverPath}/api/User/${id}`, {headers: headers})
            .then((response) => {
                this.showAlertSuccess(response.data.message)

            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                } else {
                    this.setState({errmsg: null})
                }
            })
    }
    //show modal for edit or create
    handleShowModal = (type, id, user) => {
        const showModal = true;
        this.setState({userId: id, user: user, showModal, typeModal: type})
        
    };
    //create new user
    handleCreate = (data) => {
        const users = this.state.users;
        let errmsg = this.state.errmsg
        errmsg = '';

        axios
            .post(`${serverPath}/api/User`, data, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`
            }
        })
            .then((response) => {
                if(this.state.request.last_page <= 1){

                    users.push(response.data.data)
                }
                this.setState({users})
                this.handleCloseModal()
                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                } else {
                    this.setState({errmsg: null})
                }
            })

    }
    // edit current user
    handleEdit = (data, user_id) => {
        const users = this.state.users
        let errmsg = this.state.errmsg
        errmsg = '';
        axios
            .post(`${serverPath}/api/update_user/${user_id}`, data, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`
            }
        })
            .then((response) => {
                this.handleCloseModal()
                for (let i in users) {

                    if (users[i].id == user_id) {
                        users[i] = '';
                        users[i] = response.data.data
                        break; //Stop this loop, we found it!

                    }
                    this.setState({users})
                }
                this.showAlertSuccess(response.data.message)

            }, (error) => {
                if (error.response) {
                    error.response.data.errors.map(err => (errmsg += err + " . "))
                    this.setState({show: true,errmsg})
    
                } else {
                    this.setState({errmsg: null})
                }
            });
    }
    //close modal for edit or create
    handleCloseModal = () => {
        this.setState({showModal: false,errmsg:''})
    }
    //show status for bootstrap alerts when errors
    setShow = () => {
        const show = false
        this.setState({show})
    }
    //logout action
    handleLogout = (e) => {
        const headers = {
            'Authorization': 'Bearer ' + this.props.token
        }
        axios
            .post(`${serverPath}/api/logout`, headers, {headers: headers})
            .then((response) => {
                this
                    .props
                    .Logout('');
                localStorage.clear();
            }, (error) => {
                if (error.response) {
                    this
                        .props
                        .Logout('');
                    localStorage.clear();
                }
            });
    }
    //suspend or unsuspend 
    handleToggleSuspend = (id) => {
        const users = this.state.users
        this.setState({alert: ''})
        axios
            .get(`${serverPath}/api/change_user_status/${id}`, {
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Accept': `application/json`
            }
        })
            .then((response) => {
                this.handleCloseModal()
                for (let i in users) {

                    if (users[i].id == id) {
                        users[i] = '';
                        users[i] = response.data.data
                        break; //Stop this loop, we found it!

                    }
                    this.setState({users})
                }
                this.showAlertSuccess(response.data.message)
            }, (error) => {
                if (error.response) {
                    this.setState({errmsg: error.response.data.message, show: true})
                } else {
                    this.setState({errmsg: null})
                }
            })

    }
    render() {
        if(this.state.loading == false){
            const wrapper=document.querySelector(".buffet-wrapper")
            const offset = wrapper.offsetTop
            const paginationHeight=this.state.request.last_page >1?'87px':'32px'
            wrapper.style.height = `calc(100vh - (${offset}px + ${paginationHeight} ))`
            
        }
        const permissions=this.props.permissions;
        const users = this.state.users
        const allUsers = users.length
            ? (users.map(user => {
                return (
                    <li key={user.id} className='collection-item row'>
                        <Col>
                            {user.username}
                        </Col>
                        <Col>
                            {user.status == 1
                                ? "غير معلق"
                                : "معلق "}
                        </Col>
                        <Col>
                            <div className='record-control'>
                            {user.user_type_id==3 && user.qr_code !=null?(
                                <>
                                <ReactToPrint
                                trigger={() =><Button variant="info" onClick={() => this.handleShowModal('edit', user.id, user)}><FontAwesomeIcon icon={faPrint}/></Button>
                            }
                            content={() => this.componentRef}
                            />
                              <QrCode pic={user.qr_code} ref={(el) => (this.componentRef = el)} />
                            </>
                            ):null}
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'edit-user'
                                        ? (
                                            <Button variant="success" onClick={() => this.handleShowModal('edit', user.id, user)}><FontAwesomeIcon icon={faPen}/></Button>
                                            )
                                            : (null))
                                        }))
                                        : null}
                        {permissions
                                    ? (permissions.map((permission, index) => {
                                        return (permission === 'delete-user'
                                        ? (
                                            <Button variant="danger"
                                            onClick={() => {
                                                this.showAlert('delete', user.id, user.username)
                                            }}><FontAwesomeIcon icon={faTrash}/></Button>
                                            )
                                            : (null))
                                        }))
                                        : null}
                            
                              
                            {user.status == 1
                                ? (
                                    <Button
                                    title='اقف تفعيل'
                                    variant="primary"
                                    onClick={() => {
                                        this.showAlert('suspend', user.id, user.username)
                                    }}><FontAwesomeIcon icon={faBan}/></Button>
                                    )
                                    : (
                                        <Button
                                        title='تفعيل'
                                        variant="primary"
                                        onClick={() => {
                                            this.showAlert('unsuspend', user.id, user.username)
                                        }}><FontAwesomeIcon icon={faCheck}/></Button>
                                        )}
                        </div>
                         </Col>
                    </li>
                )
            }))
            : <p className='collection-item'>لا توجد بيانات
            </p>;
       

        return (

            <div className='page-content'>
                <div className='page-header'>
                    <Container fluid>
                        <Row>
                        {permissions
                                ? (permissions.map((permission, index) => {
                                    return (permission === 'add-user'
                                        ? (
                                            <Col key={index} xs={'auto'}>
                                                <Button key={index} variant="primary" onClick={()=>this.handleShowModal("add")}>أضف مستخدم جديد</Button>
                                            </Col>
                                        )
                                        : (null))
                                }))
                                : null}
                            <Col>
                                <SearchBar/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <div className="buffet-wrapper">
                        <ul className="collection users">
                            <Planets customLoading={this.state.loading}/> {allUsers}
                        </ul>
                    </div>
                    {this.state.request.last_page >1?
                            <Pagination  goToPage={this.goToPage} currentPage={this.state.currentPage} totalPages={this.state.request.last_page}/>  :null
                        }
                    {this.state.cerrmsg
                        ? (

                            <Alert
                                show={this.state.show}
                                variant="danger"
                                dismissible
                                onClose={this.setShow}>

                                <span className='cursor' onClick={this.handleLogout}>{this.state.cerrmsg}</span>
                            </Alert>
                        )
                        : null}

                </Container>

                <Modal
                    animation={false}
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.typeModal == 'add'
                                ? 'إضافة مستخدم جديد'
                                : `تعديل بيانات ${this.state.user.username}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserForm
                            type={this.state.typeModal}
                            groups={this.state.groups}
                            token={this.props.token}
                            user={this.state.user}
                            editUser={this.handleEdit}
                            id={this.state.userId}
                            departments={this.state.departments}
                            setShow={this.setShow}
                            show={this.state.show}
                            errmsg={this.state.errmsg}
                            createUser={this.handleCreate}
                            token={this.props.token}/>
                    </Modal.Body>

                </Modal>
                {this.state.alert}
                {this.state.alertSuccess}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {token: state.auth.token, permissions: state.user.permissions, usertype: state.user.usertype}
}
const mapDispatchToProps = (dispatch) => {
    return {
        Logout: (token) => dispatch({type: 'LOGOUT', token: token})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers)
