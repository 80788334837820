import React, { Component } from 'react'
import { Button , Form , Alert } from 'react-bootstrap';
import FormData from 'form-data'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class PresetForm extends Component{
        state={
       name:this.props.preset.name?this.props.preset.name:'',
       presetItems:[],
       itemsList:[],
       itemsId:[],
       value:[],
   }
    
  
    componentWillMount(){
        const itemsList=this.state.itemsList
        this.props.items.map(item=>{
            itemsList.push({value:item.id,label:item.name})
        })
        this.setState({itemsList})
        const presetItems=this.state.presetItems
       if(this.props.presetItems){
        this.props.presetItems.map(item=>{
            presetItems.push({value:item.id,label:item.name})
        })
        this.setState({presetItems})
        this.setState({value:presetItems})

       }
        
    }
     onSelectChange = value => {
        this.setState({value});
      };
     handleSubmit = (e) => {
         const itemsId=this.state.itemsId
        e.preventDefault();
        this.state.value.map(item=>{
            itemsId.push(item.value)
        })
        this.setState({itemsId})
        const formData = new FormData(document.querySelector('#userform'));
        this.state.itemsId.map((item,index)=>{
            formData.append(`items[${index}]`,item)
        })
        this.props.type =='add'? this.props.createPreset(formData):this.props.editPreset(formData,this.props.preset.id)
    }
    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    render(){
        const categories= this.props.cats.map(cat=>{
            return(
            <option key={cat.id} value={cat.id} >{cat.name}</option>
            )
        })
        return(
            <Form  id="userform" className='text-right presetform' onSubmit={this.handleSubmit} >
                <Form.Group controlId="name">
                <label htmlFor="name">اسم المشروب</label>

                    <Form.Control required name='name' type="text" value={this.state.name} onChange={this.handleChange} placeholder="اسم المشروب" />
                </Form.Group>
                <Form.Group className='relative'>
                    <input className='form-control ' type="text" disabled value='صورة المنتج'/>
                    <div className="file btn btn-lg btn-primary col-auto">
                        تحميل
                        <input required={this.props.type=='add' ? 'required' : null} type="file"  name='image' />
                    </div>                

                </Form.Group>
                <Form.Group controlId="selectrole">
                    <Form.Control name='category_id' defaultValue={this.props.preset.category_id}  as="select">
                    <option  value="">إختر</option>
                        {categories}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                
                <Select
                closeMenuOnSelect={false}
                isSearchable
                components={animatedComponents}
                defaultValue={this.state.presetItems}
                isMulti
                value={this.state.value}
                options={this.state.itemsList}
                onChange={this.onSelectChange}
                className='form-control select-form'
                classNamePrefix="select"
                />
                </Form.Group>
               
                <Form.Group className='text-center'>
                     <Button  variant="primary" type="submit">
                      حفظ
                    </Button>
                </Form.Group>
                {this.props.errmsg &&this.props.show?(
                  
                  <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>
                   
                    {this.props.errmsg}
                  </Alert>) : null}  
            </Form>
        )}
    }
                
export default PresetForm