 import SecureLS from 'secure-ls'
var SECRET_KEY = 'dimbuffet';
var ls = new SecureLS({encodingType: 'rabbit', isCompression: true, encryptionSecret: SECRET_KEY});

const initstate={
    id:ls.get('uid')?ls.get('uid'):'',
    usertype:ls.get('type')?ls.get('type'):'',
    profilePic:ls.get('upic')?ls.get('upic'):'',
    points:ls.get('c')?ls.get('c'):'',
    credit:ls.get('cr')?ls.get('cr'):'',
    cartItems:ls.get('ucart')?JSON.parse(ls.get('ucart')):'',
    fav:ls.get('fav')?ls.get('fav'):'',
    permissions:ls.get('per')?ls.get('per'):'',
    username:ls.get('uname')?ls.get('uname'):'',
}

const userReducer = (state = initstate , action)=> {
   
     //store permissions
     if(action.type==='PERMISSIONS'){
        ls.set('per',action.permissions)    
        return{
            ...state,
            permissions:action.permissions
        }
       }
    //store logged user id
    if(action.type ==='UID' ){
        ls.set('uid', action.id);

        return{
            ...state,
            id:action.id
        }
       }
       // store usertype for usage on permissions
       if(action.type==='USER_TYPE'){
        ls.set('type',action.usertype)    
        return{
            ...state,
            usertype:action.usertype
        }
       }
       //store fav
       if(action.type==='FAV'){
        ls.set('fav',action.fav)    
        return{
            ...state,
            fav:action.fav
        }
       }
       // clear cart
       if(action.type==='CLEARCART'){
        ls.set('ucart','')    
        return{
            ...state,
            cartItems:''
        }
       }
      
       //store profile pic
       if(action.type==='PROFILE_PIC'){
        ls.set('upic',action.profilePic)    
        return{
            ...state,
            profilePic:action.profilePic
        }
       }
       // store cart
       if(action.type==='CARTITEMS'){
        ls.set('ucart',JSON.stringify(action.cartItems))    

        return{
            ...state,
            cartItems:action.cartItems
        }
       }
       // set new img
       if(action.type==='CHANGEIMG'){
        ls.set('upic',action.profilePic)    
        return{
            ...state,
            profilePic:action.profilePic
        }
       }
       // store points
       if(action.type==='POINTS'){
        ls.set('c',action.points)    
        return{
            ...state,
            points:action.points
        }
       }
       // credit type
       if(action.type==='CREDIT'){
        ls.set('cr',action.credit)    
        return{
            ...state,
            credit:action.credit
        }
       }
       //store username
       if(action.type==='USERNAME'){
        ls.set('uname',action.username)    
        return{
            ...state,
            username:action.username
        }
       }
    return state
}
export default userReducer