import React, { Component } from 'react'
import { Button, Form, Alert } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();


class AddToCart extends Component {
    state = {
        name: this.props.preset.name ? this.props.preset.name : '',
        presetItems: this.props.type == 'edit' ? this.props.presetItems : this.props.preset.items,
        presetPoints: this.props.points,
        itemsId: [],
        quantity1: [],
        quantity2: [],
        privatec: [],
        notes: this.props.type =='edit'? this.props.notes : '',
        points: ''
    }
    componentDidMount() {
        const itemsid = this.state.presetItems ? this.state.presetItems.map((item) => {
            const itemsId = this.state.itemsId
            const privatec = this.state.privatec
            const quantity1 = this.state.quantity1
            const quantity2 = this.state.quantity2
            this.props.type == 'edit' ? itemsId.push(item.item_id) : itemsId.push(item.id)
            privatec.push(item.mine)
            quantity1.push(this.props.type == 'edit' ? String(item.quantity1) : String(item.pivot.quantity))
            quantity2.push(item.quantity2 ? String(item.quantity2) : '0')
            this.setState({ itemsId })
        }) : (null)


    }
    //submitting form and check which action will go (create or edit)
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.type == 'add' ? this.props.create(this.props.selectedId, this.state.itemsId, this.state.quantity1, this.state.quantity2, this.state.privatec, this.state.notes)
            : this.props.edit(this.props.cartId, this.props.selectedId, this.state.itemsId, this.state.quantity1, this.state.quantity2, this.state.privatec, this.state.notes)

    }
    //storing input value within changing 
    handleChange = (e, index) => {
        const itemcont = this.state[e.target.name]

        itemcont[index] ? (
            itemcont[index] = e.target.value
        ) : (
                itemcont.push(e.target.value)
            );
        this.setState({ itemcont: e.target.value })

    }
    //storing check box values
    handleCheckboxChange = (e, index) => {
        const privatec = this.state[e.target.name]
        
        e.target.checked ? (privatec[index] = true) : (privatec[index] = false)

    }
    //storing text values
    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const items = this.state.presetItems ? this.state.presetItems.map((item, index) => {
            return (
                <div key={item.id} className='order-item-info'>
                    <div className='name'>
                        {this.props.type == 'edit' ?
                            (
                                <span>
                                    {item.item.name} ({item.item.label})
                                </span>
                            ) : (<span>
                                {item.name} ({item.label})
                            </span>)
                        }

                    </div>
                    <div className='qty'>
                        {/* choose fraction number of preset unit */}
                        <select required='required' defaultValue={item.quantity2 ? item.quantity2 : "0"} id={item.quantity2} name='quantity2' onChange={(e) => this.handleChange(e, index)}>
                            <option value="">
                                إختر
                                </option>
                            <option value='0'>
                                0
                                </option>
                            <option value='0.25'>
                                0.25
                                </option>
                            <option value='0.5'>
                                0.5
                                </option>
                            <option value='0.75'>
                                0.75
                                </option>
                        </select>
                        {/* choose int number of preset unit */}
                        <select required='required' defaultValue={item.quantity1 != null && item.quantity1 != 'undefined' ? item.quantity1 : item.pivot.quantity} name='quantity1' onChange={(e) => this.handleChange(e, index)}>
                            <option value="">
                                إختر
                                </option>
                            <option value='0'>
                                0
                                </option>
                            <option value='1'>
                                1
                                </option>
                            <option value='2'>
                                2
                                </option>
                            <option value='3'>
                                3
                                </option>
                            <option value='4'>
                                4
                                </option>
                            <option value='5'>
                                5
                                </option>
                            <option value='6'>
                                6
                                </option>
                            <option value='7'>
                                7
                                </option>
                            <option value='8'>
                                8
                                </option>
                            <option value='9'>
                                9
                                </option>
                            <option value='10'>
                                10
                                </option>
                        </select>
                    </div>
                    <div className='stock relative'>
                        <label className='custom-checkbox' htmlFor={item.name}>
                            {item.mine == true ? (<input defaultChecked onChange={(e) => this.handleCheckboxChange(e, index)} name="privatec" type='checkbox' id={item.name} />) : (<input onChange={(e) => this.handleCheckboxChange(e, index)} name="privatec" type='checkbox' id={item.name} />)}
                            <span className='checkmark'></span>
                        </label>
                    </div>
                </div>
            )
        }) : null

        return (
            <Form id="userform" className='text-center AddToCart' onSubmit={this.handleSubmit} >
                <Form.Group className='price'>
                    <span className='circle btn-danger'>
                        <span>

                            {this.state.presetPoints}
                        </span>
                        <small>نقاط</small>
                    </span>
                    <span className='price-choice'>
                        السعر حسب الاختيار
                    </span>
                </Form.Group>
                <div className='modal-body-title'>
                    <h4>
                        إختر المحتويات
                        </h4>
                    <h6>
                        من المخزون
                        </h6>
                </div>
                {items}
                <Form.Group>
                    <label htmlFor='notes' className='text-right'>إكتب ملاحظاتك</label>
                    <textarea id='notes' name='notes' rows='5' placeholder='اكتب ملاحظاتك هنا' value={this.state.notes} onChange={this.handleChangeText}>

                    </textarea>
                </Form.Group>
                <Button variant="primary" type="submit">
                    {this.props.type=='edit'?'تعديل':'أضف الى السلة'}
                </Button>

                {this.props.errmsg && this.props.show ? (

                    <Alert show={this.props.show} variant="danger" dismissible onClose={this.props.setShow}>

                        {this.props.errmsg}
                    </Alert>) : null}
            </Form>
        )
    }
}

export default AddToCart