import SecureLS from 'secure-ls'
var SECRET_KEY = 'dimtoken';
var ls = new SecureLS({encodingType: 'rabbit', isCompression: true, encryptionSecret: SECRET_KEY});

const initstate={
    token:ls.get('token')?ls.get('token'):'',
}

const authReducer = (state = initstate , action)=> {
   if(action.type ==='LOGIN'){
    ls.set('token',action.token)
    return{
        ...state,
        token:action.token
        
    }

   }
   else if(action.type==='LOGOUT'){
    ls.set('token','')

       return{
        ...state,
        token:null
       }
   }
    return state
}
export default authReducer