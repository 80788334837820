import React, { Component } from 'react'
import { Button , Modal , Container,Col,Row } from 'react-bootstrap';
import serverPath from '../config';
import axios from 'axios';
import {connect} from 'react-redux'
import itemImg from '../../assets/img/default.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart ,faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Planets } from 'react-preloaders';
import AddToCart from '../Order/AddToCart'
import SearchBar from '../layout/SearchBar'

class Favourites extends Component{
    constructor(props){
        super(props);
        this.state={
            presets:[],
            showModal:false,
            typeModal:'',
            selectedId:'',
            selectedItem:'',
            errmsg:'',
            show:'',
            alertDelete:'',
            alertSuccess:'',
            loading:true,
            items:'',
            categories:'',
            presetItems:[],
            selectedPoints:'',
            fav:[],
            cartItems:[]
        }
    }
   async componentDidMount(){
    await axios.get(`${serverPath}/api/Presit`, {
        headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+this.props.token
        }
    })
    .then((response) => {
        const presets= response.data.data.data;
       this.setState({presets,loading:false})
    },(error)=>{
        if(error.response.status === 403){
            this.props.history.push('/nopermission')
         }
        if(error.response){
            this.setState({errmsg : error.response.data.message,loading:false
            })  
        }
        else{
          this.setState({errmsg : null})
        }
      })
        const wrapper=document.querySelector(".buffet-wrapper")
        const offset=wrapper.offsetTop
        wrapper.style.height= 'calc(100vh - '+offset+'px - 40px)'
        await axios.get(`${serverPath}/api/Item`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            const items= response.data.data.data;
           this.setState({items}) 
        },(error)=>{
            if(error.response){
                this.setState({cerrmsg : error.response.data.message,loading:false,show:true
                })  
            }
            else{
              this.setState({cerrmsg : null})
            }
          })
          await axios.get(`${serverPath}/api/Category`, {
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            const categories= response.data.data;
           this.setState({categories,loading:false}) 
        },(error)=>{
            if(error.response){
                this.setState({cerrmsg : error.response.data.message,loading:false,show:true
                })  
            }
            else{
              this.setState({cerrmsg : null})
            }
          })
          this.setState({fav:this.props.fav})
}
    handleShowModal=(type,id,item,presetItems,points)=>{

        this.setState({selectedPoints:points,showModal:true,typeModal:type,selectedId:id?id:'',selectedItem:item?item:'',presetItems})
    }
    handleCloseModal=()=>{
        this.setState({showModal:false,typeModal:'',selectedId:'',errmsg:''})
    }
    onCancelSuccess = ()=>{
        this.setState({alertSuccess:''})
    }
    showAlertSuccess=(msg)=>{
        this.setState({
            alertSuccess:<SweetAlert
            success
            confirmBtnText="تم"
            confirmBtnBsStyle="primary"
            title={msg}
            timeout={2000}
            onConfirm={this.onCancelSuccess}
            onCancel={this.onCancelSuccess}
          >
          </SweetAlert>
        })
    }
     //add to cart function
     handleCreate = (presetId,items,quantity,quantity2,privatec,notes)=>{
        let errmsg=this.state.errmsg
        errmsg='';
        axios.post(`${serverPath}/api/add_to_cart`,
           {
                presit_id:presetId,
                items:items,
                quantity:quantity,
                quantity2:quantity2,
                mine:privatec,
                notes:notes
            }

        ,{
            headers:{
                'Authorization':`Bearer ${this.props.token}`,
                'Accept': 'application/json',
            }
        }).then((response)=>{
            const cartAllItems= response.data.cart;
            let cartItems=this.state.cartItems
            cartItems=[];
            let errmsg=this.state.errmsg
            errmsg='';
            cartAllItems.map(item=>{
                cartItems.push(item)
            })
            this.props.CartItems(cartItems)

            this.setState({cartItems})
            this.setState({showModal:false})
            this.showAlertSuccess(response.data.message)
        }, (error) => {
            if(error.response){
                // Object.keys(error.response.data.message).map(key => (
                //     errmsg+=error.response.data.message[key] +"  "
                //   ))
                errmsg=error.response.data.message
                this.setState({show : true})   
                this.setState({errmsg})   

            }
            else{
            this.setState({errmsg : null})
            }
        })
        
    }
    handleAddToFav = (preset_id)=>{
        axios.post(`${serverPath}/api/add_to_favorit`,{preset_id:preset_id} ,{
            headers: {
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.props.token
            }
        })
        .then((response) => {
            this.props.FAV(response.data.data)
            this.setState({fav:response.data.data})
            this.showAlertSuccess(response.data.message)
        },(error)=>{
            if(error.response){
                this.setState({errmsg : error.response.data.message,loading:false
                })  
            }
            else{
              this.setState({errmsg : null})
            }
          })
    }
   
    render(){
        const presets=this.state.presets.length>0 ? this.state.presets.map(preset=>{
            let presetPoints = 0;
            const fav =this.state.fav;
            const points=preset.items.length>0?preset.items.map(item=>{
                presetPoints+=  Number(item.pivot.points)
                return presetPoints 
            }):null 
            
            return(
                fav.includes(preset.id)?
                <Col  lg={3} md={6} xs={12} key={preset.id}>
                    <div className='item-card'>
                        <div className='item-quantity'>
                            <img src={preset.image ? preset.image : itemImg} alt={preset.name} title={preset.name}/>
                        </div>  
                        <div className='preset-info'>
                            <h4>{preset.name}</h4>
                            <h5>
                                {`النقاط : ${presetPoints} نقاط`}
                            </h5>
                            {preset.items.length>0?preset.items.map(item=>{
                                    return (<span className='preset-item' key={Math.random()}>{item.pivot.quantity} {item.name} </span>)
                                }):null} 
                        </div>                         
                        <div className='record-control'>
                            <Button  variant="primary" onClick={()=>this.handleShowModal("add",preset.id,preset,preset.items,presetPoints)}><FontAwesomeIcon  icon={faShoppingCart}/></Button>
                           
                           <Button  variant="danger" className='btn-fav isfav'  onClick={()=>this.handleAddToFav(preset.id)}><FontAwesomeIcon  icon={faHeart}/></Button>
                        </div>
                    </div>
                   
                </Col>:null
            )
        }):(<Col>لا توجد بيانات</Col>)
       
        return(
            <div className='page-content'>
                 <div className='page-header'>
                 <Container fluid>
                     <Row>
                        <Col>
                            <SearchBar/>
                        </Col>
                    </Row>
                    </Container>                     
                </div>
                <Container fluid>
                    <div className='buffet-wrapper presets'>
                    <Planets customLoading={this.state.loading} />

                        <Row className='stretch'>
                        <Col xs={'12'}>
                        <h1 className='title'>قائمة مفضلتي</h1>
                        </Col>
                            {this.state.fav.length>0?presets:"لا توجد بيانات"}
                            
                        </Row>
                    </div>
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.selectedItem.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddToCart 
                        selectedId={this.state.selectedId}
                        create={this.handleCreate}
                        type={this.state.typeModal}
                        preset={this.state.selectedItem} 
                        items={this.state.items}
                        cats={this.state.categories}
                        presetItems={this.state.presetItems}
                        show={this.state.show}
                        errmsg={this.state.errmsg}
                        points={this.state.selectedPoints}
                        />
                    </Modal.Body>
              
                </Modal>
                </Container>
                {this.state.alertDelete}
                  {this.state.alertSuccess}
            </div>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        token:state.auth.token,
        usertype:state.user.usertype,
        fav:state.user.fav
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
        CartItems: (cartItems)=>dispatch({type:'CARTITEMS', cartItems:cartItems}),  
        FAV:(fav) =>dispatch({type:'FAV',fav:fav}) 

    }
  }
export default connect(mapStateToProps,mapDispatchToProps) (Favourites)
