import React from 'react'
import Moment from 'moment'
function Notifications (props){
    const listing=props.notificationsList.length>0?props.notificationsList.map((item,index)=>{
        return(
        <li key={index}>
             <h4>
                {item.body}
            </h4>
            <span className='order-date'>
                {/* read about moment js */}
                {Moment(item.created_at).format("MMMM D YYYY , hh:mm A")}
            </span>
        </li>)
    }):(<li>لا توجد اشعارات</li>)
    return(
        <ul>
        {listing}
        </ul>
    )
}
    

export default Notifications